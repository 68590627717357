<template>
  <v-alert
    class="alert-style"
    :type="colorType"
    max-width="250px"
    :dismissible="dismissible"
    v-model="isShow"
    transition="scale-transition"
    >{{ title }}</v-alert
  >
</template>
<script>
export default {
  name: "Alert",
  props: {
    title: {
      type: String
    },
    colorType: {
      type: String
    },
    alertOpen: {
      type: Boolean
    },
    dismissible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow: this.alertOpen
    };
  },
  watch: {
    alertOpen: {
      handler(val) {
        this.isShow = val;
      }
    }
  }
};
</script>
<style lang="scss" scope>
.v-alert.alert-style {
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 100;
}
</style>
