import { userAPI } from "@/plugins/service";
import { format } from "date-fns";

export const state = {
  quantityOfOrder: [],
  transactionAmount: [],
  storeNumOfViews: [],
  itemNumOfViews: [],
  categoryNumOfViews: [],
  numOfNewConsumer: []
};

export const getters = {
  quantityOfOrder(state) {
    return state.quantityOfOrder;
  },
  totalQuantityOfOrder(state) {
    if (state.quantityOfOrder.length) {
      return state.quantityOfOrder.reduce((a, b) => a + b);
    } else {
      return 0;
    }
  },
  transactionAmount(state) {
    return state.transactionAmount;
  },
  totalTransactionAmount(state) {
    if (state.transactionAmount.length) {
      return state.transactionAmount.reduce((a, b) => a + b);
    } else {
      return 0;
    }
  },
  storeNumOfViews(state) {
    return state.storeNumOfViews;
  },
  totalStoreNumOfViews(state) {
    if (state.storeNumOfViews.length) {
      return state.storeNumOfViews.reduce((a, b) => a + b);
    } else {
      return 0;
    }
  },
  numOfNewConsumer(state) {
    return state.numOfNewConsumer;
  },
  totalNumOfNewConsumer(state) {
    if (state.numOfNewConsumer.length) {
      return state.numOfNewConsumer.reduce((a, b) => a + b);
    } else {
      return 0;
    }
  },
  itemNumOfViews(state) {
    return state.itemNumOfViews;
  },
  totalItemNumOfViews(state) {
    if (state.itemNumOfViews.length) {
      return state.itemNumOfViews.reduce((a, b) => a + b);
    } else {
      return 0;
    }
  },
  categoryNumOfViews(state) {
    return state.categoryNumOfViews;
  },
  totalCategoryNumOfViews(state) {
    if (state.categoryNumOfViews.length) {
      return state.categoryNumOfViews.reduce((a, b) => a + b);
    } else {
      return 0;
    }
  }
};

export const mutations = {
  CLEAR(state) {
    state.quantityOfOrder = [];
    state.transactionAmount = [];
  },
  CLEAR_NUM_VIEWS(state) {
    state.storeNumOfViews = [];
  },
  CLEAR_ITEM_VIEWS(state) {
    state.itemNumOfViews = [];
  },
  CLEAR_CATEGORY_VIEWS(state) {
    state.categoryNumOfViews = [];
  },
  CLEAR_NEW_CONSUMER(state) {
    state.numOfNewConsumer = [];
  },
  CLEAR_TIME_ARY(state) {
    state.startTimeAry = [];
  },
  CLEAR_ITEM_LIST(state) {
    state.itemList = [];
  },
  CLEAR_CATEGORY_LIST(state) {
    state.categoryList = [];
  },
  SET_TIME_ARY(state, time) {
    state.startTimeAry = time;
  },
  SET_QUANTITY_OF_ORDER(state, orders) {
    state.quantityOfOrder = orders;
  },
  SET_TRANSACTION_AMOUNT(state, amount) {
    state.transactionAmount = amount;
  },
  SET_STORE_VIEWS(state, views) {
    state.storeNumOfViews = views;
  },
  SET_ITEM_VIEWS(state, views) {
    state.itemNumOfViews = views;
  },
  SET_CATEGORY_VIEWS(state, views) {
    state.categoryNumOfViews = views;
  },
  SET_NEW_CONSUMER(state, nums) {
    state.numOfNewConsumer = nums;
  },
  SET_ITEM_LIST(state, items) {
    state.itemList = items;
  },
  SET_CATEGORY_LIST(state, categories) {
    state.categoryList = categories;
  }
};

export const actions = {
  getTradingVolume(
    { dispatch, commit, rootGetters },
    { storeIDs, startTime, endTime, intervalUnit, intervalStep }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      commit("CLEAR");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = rootGetters["user/uid"];
          return userAPI.getTradingVolume(
            idToken,
            userID,
            storeIDs,
            startTime,
            endTime,
            intervalUnit,
            intervalStep
          );
        })
        .then(res => {
          const tradingVolume = res["trading_volume"];
          commit(
            "SET_QUANTITY_OF_ORDER",
            tradingVolume.map(item => {
              return item["quantity_of_order"];
            })
          );
          commit(
            "SET_TRANSACTION_AMOUNT",
            tradingVolume.map(item => {
              return item["transaction_amount"];
            })
          );
          if (intervalUnit === "day") {
            commit(
              "SET_TIME_ARY",
              tradingVolume.map(item => {
                return format(new Date(item["start_time"]), "MM/dd");
              })
            );
          } else {
            commit("CLEAR_TIME_ARY");
          }
          resolve();
        })
        .catch(err => {
          commit("CLEAR");
          reject("get trading volume error: " + err);
        });
    });
  },
  getStoreNumberOfViews(
    { dispatch, commit, rootGetters },
    {
      storeID,
      itemID,
      categoryID,
      startTime,
      endTime,
      intervalUnit,
      intervalStep
    }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      commit("CLEAR_NUM_VIEWS");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = rootGetters["user/uid"];
          return userAPI.getNumberOfViews(
            idToken,
            userID,
            storeID,
            itemID,
            categoryID,
            startTime,
            endTime,
            intervalUnit,
            intervalStep
          );
        })
        .then(res => {
          const values = res.values;
          commit(
            "SET_STORE_VIEWS",
            values.map(item => {
              return item["value"];
            })
          );
          resolve();
        })
        .catch(err => {
          commit("CLEAR_NUM_VIEWS");
          reject("get store num of views error: " + err);
        });
    });
  },
  getItemNumberOfViews(
    { dispatch, commit, rootGetters },
    { storeID, itemID, startTime, endTime, intervalUnit, intervalStep }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      commit("CLEAR_NUM_VIEWS");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = rootGetters["user/uid"];
          let categoryID = "";
          return userAPI.getNumberOfViews(
            idToken,
            userID,
            storeID,
            categoryID,
            itemID,
            startTime,
            endTime,
            intervalUnit,
            intervalStep
          );
        })
        .then(res => {
          const values = res.values;
          commit(
            "SET_ITEM_VIEWS",
            values.map(item => {
              return item["value"];
            })
          );
          resolve();
        })
        .catch(err => {
          commit("CLEAR_ITEM_VIEWS");
          reject("get item num of views error: " + err);
        });
    });
  },
  getCategoryNumberOfViews(
    { dispatch, commit, rootGetters },
    { storeID, categoryID, startTime, endTime, intervalUnit, intervalStep }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      commit("CLEAR_NUM_VIEWS");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = rootGetters["user/uid"];
          let itemID = "";
          return userAPI.getNumberOfViews(
            idToken,
            userID,
            storeID,
            categoryID,
            itemID,
            startTime,
            endTime,
            intervalUnit,
            intervalStep
          );
        })
        .then(res => {
          const values = res.values;
          commit(
            "SET_CATEGORY_VIEWS",
            values.map(item => {
              return item["value"];
            })
          );
          resolve();
        })
        .catch(err => {
          commit("CLEAR_CATEGORY_VIEWS");
          reject("get category num of views error: " + err);
        });
    });
  },
  getNumberOfNewConsumer(
    { dispatch, commit, rootGetters },
    { storeID, startTime, endTime, intervalUnit, intervalStep }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      commit("CLEAR_NEW_CONSUMER");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = rootGetters["user/uid"];
          return userAPI.getNumberOfNewConsumer(
            idToken,
            userID,
            storeID,
            startTime,
            endTime,
            intervalUnit,
            intervalStep
          );
        })
        .then(res => {
          const values = res.values;
          commit(
            "SET_NEW_CONSUMER",
            values.map(item => {
              return item["value"];
            })
          );
          resolve();
        })
        .catch(err => {
          commit("CLEAR_NEW_CONSUMER");
          reject("get num of new consumer error: " + err);
        });
    });
  }
};
