<template>
  <div class="messages-menu">
    <v-menu
      offset-y
      nudge-bottom="16"
      max-width="400"
      max-height="100%"
      v-model="menu_status"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on" :small="isMobile">
          <v-badge
            :content="unlistedMessageCountContent"
            :value="unlistedMessageIDs.length"
            color="red"
            overlap
          >
            <v-icon>mdi-message-text</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-list>
        <v-subheader v-text="$t('__messages')"></v-subheader>
        <template v-for="message in messages">
          <v-divider :key="message.id + '-divider'"></v-divider>
          <v-list-item
            class="pa-0"
            :key="message.id"
            @click="messageClicked(message)"
            color="primary"
            :input-value="!message.is_read"
          >
            <table class="message-data-table">
              <tr>
                <th class="message-data-table__text">{{ message.text }}</th>
              </tr>
              <tr>
                <td class="message-data-table__time">{{ message.timetext }}</td>
              </tr>
            </table>
          </v-list-item>
        </template>
        <v-divider></v-divider>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import format from "date-fns/format";

export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      userIsSignIn: "user/isSignIn",
      userMessages: "messages/list",
    }),
    unlistedMessageIDs() {
      const list = [];
      for (const message of this.messages) {
        if (!message.is_listed) {
          list.push(message.id);
        }
      }
      return list;
    },
    unlistedMessageCountContent() {
      const count = this.unlistedMessageIDs.length;
      if (count > 99) {
        return "99+";
      }
      return count;
    },
  },
  data() {
    return {
      messages: [],
      menu_status: false,
    };
  },
  created() {
    setInterval(this.update, 10000);
  },
  methods: {
    update() {
      this.messages = [];
      for (const userMessage of this.userMessages) {
        const message = userMessage.data;
        message.id = userMessage.id;

        // translate
        const translationKey = "message_" + message.key;
        const translationText = this.$t(translationKey);
        if (translationText !== translationKey) {
          switch (message.key) {
            case "order_created":
              message.text = translationText.replace(
                "%1",
                message.data.order_id
              );
              break;
            case "order_paid_amount":
              message.text = translationText
                .replace("%1", message.data.order_id)
                .replace("%2", message.data.amount);
              break;
            case "order_paid":
              message.text = translationText.replace(
                "%1",
                message.data.order_id
              );
              break;
            case "order_cancellation_requested":
              message.text = translationText.replace(
                "%1",
                message.data.order_id
              );
              break;
            case "order_received_transferinfo":
              message.text = translationText.replace(
                "%1",
                message.data.order_id
              );
              break;
            case "order_received":
              message.text = translationText.replace(
                "%1",
                message.data.order_id
              );
              break;
            default:
              message.text = translationText;
          }
        }

        message.timetext = `${this.timestamp2Date(
          message.time
        )} ${this.timestamp2Time(message.time)} (${this.countTime(
          message.time
        )})`;
        this.messages.push(message);
      }
    },
    timestamp2Date(timestamp) {
      return format(timestamp.toDate(), "yyyy/MM/dd");
    },
    timestamp2Time(timestamp) {
      return format(timestamp.toDate(), "HH:mm:ss");
    },
    countTime(timestamp) {
      const date = timestamp.toDate();
      const nowDate = Date.now();
      // second
      const diff_s = Math.round((nowDate - date) / 1000);
      if (diff_s < 60) {
        return `${this.$t("__few_seconds_ago")}`;
      }
      // minutes
      const diff_m = Math.round(diff_s / 60);
      if (diff_m < 60) {
        return `${diff_m} ${this.$t("__minutes_ago")}`;
      }
      // hour
      const diff_hr = Math.round(diff_m / 60);
      if (diff_hr < 24) {
        return `${diff_hr} ${this.$t("__hours_ago")}`;
      }
      // day
      const diff_day = Math.round(diff_hr / 24);
      if (diff_day < 7) {
        return `${diff_day} ${this.$t("__days_ago")}`;
      }
      // week
      const diff_week = Math.round(diff_day / 7);
      if (diff_day < 30) {
        return `${diff_week} ${this.$t("__weeks_ago")}`;
      }
      // month
      const diff_month = Math.round(diff_day / 30);
      if (diff_day < 365) {
        return `${diff_month} ${this.$t("__months_ago")}`;
      }
      // year
      const diff_year = Math.round(diff_day / 365);
      return `${diff_year} ${this.$t("__years_ago")}`;
    },
    messageClicked(message) {
      if (!message.is_read) {
        this.$store.dispatch("messages/updateMessageIsRead", {
          id: message.id,
          isRead: true,
        });
      }
      this.$router
        .push({
          path: message.url,
        })
        .catch(() => {});
    },
  },
  watch: {
    userIsSignIn: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.$store.dispatch("messages/setOnSnapshot", {
            onAdded: ({ data }) => {
              Vue.notify({
                group: "messages",
                title: data.body,
                text: `${this.timestamp2Date(data.time)} ${this.timestamp2Time(
                  data.time
                )} (${this.countTime(data.time)})`,
              });
            },
          });
        } else {
          this.$store.dispatch("messages/unsubscribeOnSnapshotUserMessages");
        }
      },
    },
    menu_status(newValue) {
      if (newValue && this.unlistedMessageIDs.length > 0) {
        for (const messageId of this.unlistedMessageIDs) {
          this.$store.dispatch("messages/updateMessageIsListed", {
            id: messageId,
            isListed: true,
          });
        }
      }
    },
    userMessages: {
      handler() {
        this.update();
      },
    },
  },
};
</script>

<style lang="scss">
.message-data-table {
  border: 0;
  color: black;

  &__text {
    text-align: left;
    font-size: 1em;
    line-height: 1em;
    padding: 8px 8px 0 8px;
  }
  &__time {
    padding: 0 8px 8px 8px;
    font-size: 0.8em;
  }
}
</style>