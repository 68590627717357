import { ServiceError, callService } from "../..";

const ServiceURL = "https://api-user-service-th4n4u7taa-an.a.run.app";

/**
 * call api
 * @param {string} api
 * @param {string} idToken
 * @param {Object} data
 * @returns {Promise<any>}
 */
export const call = function(api, idToken, data = {}) {
  return callService(`${ServiceURL}/api/${api}`, idToken, data).then(res => {
    return new Promise((resolve, reject) => {
      if (res.ok) resolve(res.result);
      else reject(new ServiceError(res.error_code, res.description));
    });
  });
};

export {
  getUser,
  getUserLimits,
  getInvitationCode,
  isInvitationCodeExist,
  getUserLevel,
  backupUser
} from "./user";

export {
  getUserStores,
  getStore,
  setStore,
  getStoreCategories,
  getStoreItems,
  getCategory,
  createCategory,
  setCategory,
  deleteCategory,
  getCategoryItems,
  getItem,
  createItem,
  setItem,
  publishItem,
  delistItem,
  deleteItem,
  addPaymentMethod,
  setPaymentMethod,
  deletePaymentMethod,
  getPaymentMethods,
  getPaymentMethod,
  addShippingChannel,
  setShippingChannel,
  deleteShippingChannel,
  getShippingChannels,
  setMessengerTelegramBotData,
  getMessengerTelegramBotData,
  setAutoReplyOrderTime_week,
  getAutoReplyOrderTime,
  addCreateStoreApplicationForm,
  setCreateStoreApplicationForm,
  setCreateStoreApplicationFormPhotoURL,
  submitCreateStoreApplicationForm,
  getCreateStoreApplicationForm,
  getCreateStoreApplicationForms,
  setOnePageWebData,
  getOnePageWebData
} from "./store";

export {
  getOrders,
  getOrder,
  acceptOrder,
  cancelOrder,
  setOrderShipped,
  completeOrder,
  replyOrderCancellation,
  getOrderCancellationResult
} from "./order";

export {
  getPayment,
  getPayments,
  getPaidAmount,
  addPayment,
  refundPayment,
  getTransferInfo,
  getTransferInfos,
  replyTransferInfo
} from "./payment";

export { getCronJob } from "./cron-jobs";

export {
  addLiveSelling,
  setLiveSelling,
  deleteLiveSelling,
  getLiveSelling,
  getLiveSellings,
  addLiveSellingItemCronJob,
  setLiveSellingItemCronJob,
  deleteLiveSellingItemCronJob
} from "./module";

export {
  addStoreMessageCronJob,
  setStoreMessageCronJob,
  deleteStoreMessageCronJob,
  getStoreMessageCronJob,
  getStoreMessageCronJobs,
  executeStoreMessageCronJob,
  getStoreMessageCronJobExecutionHistories,
  getStoreMessageCronJobExecutionCount
} from "./module";

export {
  getPlan,
  getUserPlan,
  getUserPlanHistories,
  getBillings,
  getBilling
} from "./charge";

export {
  getTradingVolume,
  getSignInHistories,
  getNumberOfViews,
  getNumberOfNewConsumer
} from "./analytics";
