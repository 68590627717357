import Vue from "vue";
import { userAPI } from "@/plugins/service";

export const state = {
  list: [],
  paymentLists: {},
  transferInfoLists: {},
  paidAmountDatas: {}
};
export const getters = {
  list(state) {
    return state.list;
  },
  paymentLists(state) {
    return state.paymentLists;
  },
  transferInfoLists(state) {
    return state.transferInfoLists;
  },
  paidAmountDatas(state) {
    return state.paidAmountDatas;
  }
};
export const mutations = {
  CLEAR(state) {
    state.list = [];
  },
  PUSH(state, { id, data }) {
    const foundIndex = state.list.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.list.splice(foundIndex, 1, { id, data });
    else state.list.push({ id, data });
  },
  PUSH_FRONT(state, { id, data }) {
    const foundIndex = state.list.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.list.splice(foundIndex, 1, { id, data });
    else state.list.unshift({ id, data });
  },
  REMOVE(state, id) {
    const foundIndex = state.list.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.list.splice(foundIndex, 1);
  },
  CLEAR_PAYMENTS(state, { orderID }) {
    Vue.set(state.paymentLists, orderID, []);
  },
  PUSH_PAYMENT(state, { orderID, paymentID, data }) {
    if (!state.paymentLists[orderID]) Vue.set(state.paymentLists, orderID, []);
    const foundIndex = state.paymentLists[orderID].findIndex(
      s => s.id == paymentID
    );
    if (foundIndex >= 0)
      state.paymentLists[orderID].splice(foundIndex, 1, {
        id: paymentID,
        data
      });
    else state.paymentLists[orderID].push({ id: paymentID, data });
  },
  REMOVE_PAYMENT(state, { orderID, paymentID }) {
    if (state.paymentLists[orderID]) {
      const foundIndex = state.paymentLists[orderID].findIndex(
        s => s.id == paymentID
      );
      if (foundIndex >= 0) state.paymentLists[orderID].splice(foundIndex, 1);
    }
  },
  CLEAR_TRANSFERINFOS(state, { orderID }) {
    Vue.set(state.transferInfoLists, orderID, []);
  },
  PUSH_TRANSFERINFO(state, { orderID, transferInfoID, data }) {
    if (!state.transferInfoLists[orderID])
      Vue.set(state.transferInfoLists, orderID, []);
    const foundIndex = state.transferInfoLists[orderID].findIndex(
      s => s.id == transferInfoID
    );
    if (foundIndex >= 0)
      state.transferInfoLists[orderID].splice(foundIndex, 1, {
        id: transferInfoID,
        data
      });
    else state.transferInfoLists[orderID].push({ id: transferInfoID, data });
  },
  REMOVE_TRANSFERINFO(state, { orderID, transferInfoID }) {
    if (state.transferInfoLists[orderID]) {
      const foundIndex = state.transferInfoLists[orderID].findIndex(
        s => s.id == transferInfoID
      );
      if (foundIndex >= 0)
        state.transferInfoLists[orderID].splice(foundIndex, 1);
    }
  },
  PUSH_PAID_AMOUNT(state, { orderID, amount }) {
    if (!state.paidAmountDatas[orderID])
      Vue.set(state.paidAmountDatas, orderID, []);
    state.paidAmountDatas[orderID] = { orderID, amount };
  }
};
export const actions = {
  getOrders(
    { state, dispatch, commit, rootGetters },
    { userID, storeID, consumerID, status, startTime, endTime }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      commit("CLEAR");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.getOrders(
            idToken,
            userID,
            storeID,
            consumerID,
            status,
            startTime,
            endTime
          );
        })
        .then(res => {
          for (const orderID of res.order_ids) {
            commit("PUSH", {
              id: orderID,
              data: res.orders[orderID]
            });
            dispatch("getPaidAmount", orderID);
            dispatch("getPayments", { orderID });
            if (res.orders[orderID].payment_method === "transfer") {
              dispatch("getTransferInfos", { orderID });
            }
          }
          resolve(state.list);
        })
        .catch(err => reject("get orders error: " + err));
    });
  },
  getPaidAmount({ rootGetters, dispatch, commit }, orderID) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.getPaidAmount(idToken, orderID);
        })
        .then(res => {
          commit("PUSH_PAID_AMOUNT", {
            orderID: res["order_id"],
            amount: res.amount
          });
          resolve();
        })
        .catch(err => reject("get paid amount error: " + err));
    });
  },
  getPayments({ commit, dispatch, rootGetters }, { orderID }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.getPayments(idToken, orderID);
        })
        .then(res => {
          const orderID = res.order_id;
          const paymentIDs = res.payment_ids;
          for (const paymentID of paymentIDs) {
            const data = res.payments[paymentID];
            commit("PUSH_PAYMENT", { orderID, paymentID, data });
          }
          resolve();
        })
        .catch(err => reject("get payments error: " + err));
    });
  },
  getTransferInfos({ rootGetters, dispatch, commit }, { orderID }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.getTransferInfos(idToken, orderID);
        })
        .then(res => {
          const orderID = res.order_id;
          const transferInfoIDs = res.transfer_info_ids;
          for (const transferInfoID of transferInfoIDs) {
            const data = res.transfer_infos[transferInfoID];
            commit("PUSH_TRANSFERINFO", { orderID, transferInfoID, data });
          }
          resolve();
        })
        .catch(err => reject("get transfer infos error: " + err));
    });
  },
  acceptOrder({ dispatch, rootGetters }, { id }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.acceptOrder(idToken, { orderID: id });
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("accept order error: " + err));
    });
  },
  cancelOrder({ dispatch, rootGetters }, { id }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.cancelOrder(idToken, { orderID: id });
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("cancel order error: " + err));
    });
  },
  setOrderShipped(
    { dispatch, rootGetters },
    { orderID, logisticsNumber, logisticsCompanyName, logisticsTrackingURL }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.setOrderShipped(
            idToken,
            orderID,
            logisticsNumber,
            logisticsCompanyName,
            logisticsTrackingURL
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("set order shipped error: " + err));
    });
  },
  completeOrder({ dispatch, rootGetters }, { orderID }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.completeOrder(idToken, orderID);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("complete order error: " + err));
    });
  },
  addPayment(
    { dispatch, rootGetters },
    {
      orderID,
      paymentMethodID,
      method,
      status,
      amount,
      bankCode,
      account,
      accountLast5Digits
    }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.addPayment(
            idToken,
            orderID,
            paymentMethodID,
            method,
            status,
            amount,
            bankCode,
            account,
            accountLast5Digits
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("add payment error: " + err));
    });
  },
  replyOrderCancellation(
    { dispatch, rootGetters },
    { id, accept, description }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.replyOrderCancellation({
            idToken,
            orderID: id,
            accept,
            description
          });
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("reply order cancellation error: " + err));
    });
  },
  replyTransferInfo(
    { rootGetters, dispatch },
    { orderID, transferInfoID, confirm, description }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.replyTransferInfo(
            idToken,
            orderID,
            transferInfoID,
            confirm,
            description
          );
        })
        .then(() => resolve())
        .catch(err => reject("reply transfer info error: " + err));
    });
  }
};
