<template>
  <div class="app-bar">
    <v-app-bar app clipped-left color="primary" dark>
      <v-app-bar-nav-icon
        v-if="isSignIn && isMobile"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer v-if="!isMobile" />
      <router-link
        :to="{ name: 'Home' }"
        class="d-flex align-center logo-router-link"
      >
        <v-img
          alt="Logo"
          class="shrink mr-4"
          contain
          src="../assets/logo.svg"
          transition="scale-transition"
          width="36"
        />
        <v-toolbar-title class="headline">
          {{ $t("__title") }}
        </v-toolbar-title>
      </router-link>
      <v-spacer v-if="!isMobile" />
      <template v-if="!$vuetify.breakpoint.mobile">
        <LanguageSelect />
      </template>
      <template v-if="isSignIn">
        <MessagesMenu
          v-bind:class="{
            'absolute-right': $vuetify.breakpoint.mobile,
            'mx-1': $vuetify.breakpoint.mobile
          }"
          :isMobile="$vuetify.breakpoint.mobile"
        />
      </template>
    </v-app-bar>

    <v-navigation-drawer
      v-if="isSignIn"
      v-model="drawer"
      :absolute="isMobile"
      :temporary="isMobile"
      :permanent="!isMobile"
      :app="!isMobile"
      :clipped="!isMobile"
    >
      <v-list nav dense expand>
        <template v-for="(item, i) in items">
          <template v-if="item.to">
            <v-list-item :key="i" :to="item.to" link>
              <v-list-item-icon>
                <v-icon>{{
                  item.icon ? item.icon : "mdi-circle-medium"
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-group :key="i" no-action :prepend-icon="item.icon">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(subItem, subI) in item.childs">
                <v-list-item
                  class="pl-6"
                  :key="`${i}-${subI}`"
                  :to="subItem.to"
                  :href="subItem.href"
                  :target="subItem.href ? '_blank' : ''"
                  v-if="subItem.to || subItem.href"
                >
                  <v-list-item-icon
                    ><v-icon>{{
                      subItem.icon ? subItem.icon : "mdi-circle-small"
                    }}</v-icon></v-list-item-icon
                  >
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="pl-6"
                  :key="`${i}-${subI}`"
                  v-else-if="subItem.event === 'store'"
                  link
                  @click="toStore"
                >
                  <v-list-item-icon>
                    <v-icon>
                      {{ subItem.icon ? subItem.icon : "mdi-circle-small" }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
          <v-divider :key="`${i}-divider`" />
        </template>
      </v-list>

      <template v-if="$vuetify.breakpoint.mobile">
        <v-divider></v-divider>
        <div class="pa-2">
          <LanguageSelect />
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LanguageSelect from "./LanguageSelect";
import MessagesMenu from "./MessagesMenu";

export default {
  name: "AppBar",
  components: {
    LanguageSelect,
    MessagesMenu
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      isSignIn: "user/isSignIn",
      storeHistoryID: "store/storeHistoryID",
      storeIDs: "stores/ids"
    })
  },
  data() {
    return {
      drawer: false,
      items: [
        {
          title: this.$t("__storemanagetools_title"),
          icon: "mdi-store",
          childs: [
            {
              title: this.$t("__home_to_stores"),
              event: "store"
            },
            {
              title: this.$t("__home_to_stores_guidebook"),
              href:
                "https://sites.google.com/chia-market.com/docs/%E9%A6%96%E9%A0%81?authuser=0"
            }
          ]
        },
        {
          title: this.$t("__order_managetools_title"),
          icon: "mdi-clipboard-list-outline",
          childs: [
            {
              title: this.$t("__order_list_title"),
              to: { name: "Orders" }
            }
          ]
        },
        {
          title: this.$t("__transaction_analysis_title"),
          icon: "mdi-chart-areaspline",
          childs: [
            {
              title: this.$t("__transaction_basic_analysis"),
              to: { name: "TransactionAnalytics" }
            }
          ]
        },
        {
          title: this.$t("__storemessagecronjobs"),
          icon: "mdi-message-text-clock-outline",
          to: { name: "StoreMessageCronJobs" }
        },
        {
          title: this.$t("__storeplan_and_bill"),
          icon: "mdi-wallet-outline",
          childs: [
            {
              title: this.$t("__storeplan"),
              to: { name: "Plan" }
            },
            {
              title: this.$t("__storebill"),
              to: { name: "Billings" }
            }
          ]
        }
      ]
    };
  },
  methods: {
    toStore() {
      if (this.storeHistoryID) {
        this.loadUserStores();
      } else {
        this.loadUserStoresAndToStorePage();
      }
    },
    loadUserStores() {
      this.$store
        .dispatch("stores/getUserStores")
        .then(() => {
          this.checkStorePermission();
        })
        .catch(err => {
          console.log(err);
        });
    },
    loadUserStoresAndToStorePage() {
      this.$store.dispatch("stores/getUserStores").then(() => {
        this.$router.replace({
          name: "Store",
          params: { id: this.storeIDs[0] }
        });
      });
    },
    checkStorePermission() {
      let permissionAry = this.storeIDs.filter(item => {
        return item === this.storeHistoryID;
      });
      if (permissionAry.length) {
        this.$router.replace({
          name: "Store",
          params: { id: this.storeHistoryID }
        });
      } else {
        this.loadUserStoresAndToStorePage();
      }
    }
  }
};
</script>

<style scoped>
.absolute-left {
  position: absolute;
  left: 0;
}
.logo-router-link {
  color: inherit;
  text-decoration: inherit;
}
.absolute-right {
  position: absolute;
  right: 0;
}
@media screen and (min-width: 1000px) {
  .logo-router-link {
    color: inherit;
    text-decoration: inherit;
  }
}

@media screen and (max-width: 999px) and (min-width: 600px) {
  .v-application .headline {
    color: #fff;
  }
}

@media screen and (max-width: 599px) {
  .spacer {
    flex-grow: 0 !important;
  }
  .v-application .headline {
    font-size: 16px !important;
    color: #fff;
  }
  .v-application .mr-4 {
    margin-right: 0px !important;
  }
}
</style>
