import { userAPI } from "@/plugins/service";

export const state = {
  userID: "",
  datas: null,
  billingID: "",
  data: null,
  planName: ""
};

export const getters = {
  userID(state) {
    return state.userID;
  },
  datas(state) {
    return state.datas;
  },
  billingID(state) {
    return state.billingID;
  },
  data(state) {
    return state.data;
  },
  planName(state) {
    return state.planName;
  }
};

export const mutations = {
  CLEAR_BILLINGS(state) {
    state.datas = [];
  },
  PUSH_BILLING_DATAS(state, { id, data }) {
    const foundIndex = state.datas.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.datas.splice(foundIndex, 1, { id, data });
    else state.datas.push({ id, data });
  },
  SET_BILLING_DATA(state, { id, data }) {
    state.billingID = id;
    state.data = data;
  },
  SET_PLAN_NAME(state, planName) {
    state.planName = planName;
  }
};

export const actions = {
  getBillings({ dispatch, rootGetters, commit }, { userID, status }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.getBillings(idToken, userID, status);
        })
        .then(res => {
          commit("CLEAR_BILLINGS");
          for (const billingID of res["billing_ids"]) {
            commit("PUSH_BILLING_DATAS", {
              id: billingID,
              data: res["billings"][billingID]
            });
          }
          resolve();
        })
        .catch(err => reject("get billings error: " + err));
    });
  },
  getBilling({ dispatch, rootGetters, commit }, billingID) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.getBilling(idToken, billingID);
        })
        .then(res => {
          commit("SET_BILLING_DATA", {
            id: res["billing_id"],
            data: res["billing"]
          });
          let planID = res["billing"]["plan_id"];
          return dispatch("getPlanName", planID);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("get billing error: " + err));
    });
  },
  getPlanName({ rootGetters, dispatch, commit }, planID) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.getPlan(idToken, planID);
        })
        .then(res => {
          commit("SET_PLAN_NAME", res.plan.name);
          resolve();
        })
        .catch(err => reject("get plan name error: " + err));
    });
  }
};
