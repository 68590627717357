import { call } from '.'

/**
 * get orders
 * @param {string} idToken
 * @param {string} userID or storeID
 * @param {string} storeID or userID
 * @param {string} consumerID
 * @param {string} status (all, unpaid, unaccepted, accepted, shipped, received, completed, cancelled)
 * @param {Date} startTime use zero time as empty ('0001-01-01T00:00:00Z')
 * @param {Date} endTime use zero time as empty ('0001-01-01T00:00:00Z')
 * @returns {Promise<Object>} order_ids and orders
 */
const getOrders = function(idToken, userID, storeID, consumerID, status, startTime, endTime) {
    return call('getOrders', idToken, {
        'user_id': userID,
        'store_id': storeID,
        'consumer_id': consumerID,
        'status': status,
        'start_time': startTime,
        'end_time': endTime
    })
}

/**
 * get order
 * @param {string} idToken
 * @param {string} orderID required
 * @returns {Promise<Object>} order_id and order
 */
const getOrder = function(idToken, orderID) {
    return call('getOrder', idToken, {
        'order_id': orderID
    })
}

/**
 * accept order
 * @param {string} idToken
 * @param {string} orderID required
 * @returns {Promise<string>} "success"
 */
const acceptOrder = function(idToken, orderID) {
    return call('acceptOrder', idToken, {
        'order_id': orderID
    })
}

/**
 * cancel order
 * @param {string} idToken
 * @param {string} orderID required
 * @returns {Promise<string>} "success"
 */
const cancelOrder = function(idToken, orderID) {
    return call('cancelOrder', idToken, {
        'order_id': orderID
    })
}

/**
 * set order shipped
 * @param {string} idToken
 * @param {string} orderID required
 * @param {string} logisticsNumber required
 * @param {string} logisticsCompanyName
 * @param {string} logisticsTrackingURL
 * @returns {Promise<string>} "success"
 */
const setOrderShipped = function(idToken, orderID, logisticsNumber, logisticsCompanyName, logisticsTrackingURL) {
    return call('setOrderShipped', idToken, {
        'order_id': orderID,
        'logistics_number': logisticsNumber,
        'logistics_company_name': logisticsCompanyName,
        'logistics_tracking_url': logisticsTrackingURL
    })
}

/**
 * complete order
 * @param {string} idToken
 * @param {string} orderID required
 * @returns {Promise<string>} "success"
 */
const completeOrder = function(idToken, orderID) {
    return call('completeOrder', idToken, {
        'order_id': orderID
    })
}

/**
 * reply order cancellation
 * @param {string} idToken
 * @param {string} orderID required
 * @param {boolean} accept
 * @param {string} description
 * @returns {Promise<string>} "success"
 */
const replyOrderCancellation = function(idToken, orderID, accept, description) {
    return call('replyOrderCancellation', idToken, {
        'order_id': orderID,
        'accept': accept,
        'description': description
    })
}

/**
 * get order cancellation result
 * @param {string} idToken
 * @param {string} orderID required
 * @param {string} orderCancellationResultID required
 * @returns {Promise<Object>} order_id, order_cancellation_result_id and order_cancellation_result
 */
const getOrderCancellationResult = function(idToken, orderID, orderCancellationResultID) {
    return call('getOrderCancellationResult', idToken, {
        'order_id': orderID,
        'order_cancellation_result_id': orderCancellationResultID,
    })
}

export {
    getOrders,
    getOrder,
    acceptOrder,
    cancelOrder,
    setOrderShipped,
    completeOrder,
    replyOrderCancellation,
    getOrderCancellationResult
}
