import { call } from '.'

/**
 * get cron job
 * @param {string} idToken
 * @param {string} cronJobID
 * @returns {Promise<Object>} cron_job_id and cron_job
 */
const getCronJob = function(idToken, cronJobID) {
    return call('getCronJob', idToken, {
        'cron_job_id': cronJobID
    })
}

export {
    getCronJob
}
