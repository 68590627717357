import { call } from ".";

/**
 * get plan
 * @param {string} idToken
 * @param {string} planID
 * @returns {Promise<Object>} plan_id and plan
 */
const getPlan = function(idToken, planID) {
  return call("getPlan", idToken, {
    plan_id: planID
  });
};

/**
 * get user plan
 * @param {string} idToken
 * @param {string} userID
 * @returns {Promise<Object>} plan_of_user
 */
const getUserPlan = function(idToken, userID) {
  return call("getUserPlan", idToken, {
    user_id: userID
  });
};

/**
 * get user plan histories
 * @param {string} idToken
 * @param {string} userID
 * @returns {Promise<Object>} []plan_of_user{}
 */
const getUserPlanHistories = function(idToken, userID) {
  return call("getUserPlanHistories", idToken, {
    user_id: userID
  });
};

/**
 * get billings
 * @param {string} idToken
 * @param {string} userID
 * @param {string} status
 * @returns {Promise<Object>} user_id, billing_ids[string],billings[billing_id:string]billing{}
 */
const getBillings = function(idToken, userID, status) {
  return call("getBillings", idToken, {
    user_id: userID,
    status: status
  });
};

/**
 * get billings
 * @param {string} idToken
 * @param {string} billingID
 * @returns {Promise<Object>} billing_id, billing
 */
const getBilling = function(idToken, billingID) {
  return call("getBilling", idToken, {
    billing_id: billingID
  });
};

export { getPlan, getUserPlan, getUserPlanHistories, getBillings, getBilling };
