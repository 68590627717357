import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "@/plugins/firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("@/views/SignIn.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/user",
    name: "User",
    component: () => import("@/views/User.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/store/:id",
    name: "Store",
    component: () => import("@/views/StoreManageTools/Store.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/order-list",
    name: "Orders",
    component: () => import("@/views/OrderManageTools/Orders.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/order/:id",
    name: "Order",
    component: () => import("@/views/OrderManageTools/Order.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/transaction-analytics",
    name: "TransactionAnalytics",
    component: () => import("@/views/Analytics/TransactionAnalytics.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/store-message-cron-jobs",
    name: "StoreMessageCronJobs",
    component: () => import("@/views/StoreMessageCronJobs.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/plan",
    name: "Plan",
    component: () => import("@/views/PlanAndBill/plan.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/billing-list",
    name: "Billings",
    component: () => import("@/views/PlanAndBill/Billings.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/billing/:id",
    name: "Billing",
    component: () => import("@/views/PlanAndBill/Billing.vue"),
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!currentUser) {
      next({ name: "SignIn", query: { redirect: to.fullPath } });
      return;
    }
  }
  next();
});

export default router;
