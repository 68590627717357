<template>
  <div class="photo-url-input">
    <v-text-field
      v-model="inputValue"
      type="url"
      :persistent-hint="persistentHint"
      :hint="hint"
      :label="label"
      :outlined="outlined"
      :dense="dense"
      :required="required"
      :rules="[...rules, urlRule]"
    >
      <template v-slot:append-outer>
        <v-menu style="top: -12px" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="margin-top: -8px"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t("__photo_url_from") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item link>
              <v-list-item-title @click="toParsePhotoURLGoogleDrive">{{
                $t("__photo_url_from_googledrive")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-text-field>
    <v-dialog max-width="400px" v-model="parsePhotoURLDialogShow">
      <v-card>
        <v-card-title>
          <div>{{ $t("__photo_url_from_googledrive") }}</div>
        </v-card-title>
        <v-card-text class="pa-6">
          <v-text-field
            dense
            outlined
            v-model="parsePhotoURLDialogData.text"
            :label="$t('__photo_url_from_googledrive_filelink')"
            :error-messages="parsePhotoURLDialogData.error"
            @input="parsePhotoURLDialogData.error = ''"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="toParsePhotoURLCancel">{{
            $t("__cancel")
          }}</v-btn>
          <v-btn text color="primary" @click="toParsePhotoURLConfirm">{{
            $t("__confirm")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PhotoURLInput",
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ""
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  data() {
    return {
      urlRule: v => !v || this.isURL(v) || this.$t("__url_invalid"),
      parsePhotoURLDialogShow: false,
      parsePhotoURLDialogData: {
        from: "",
        text: "",
        error: ""
      }
    };
  },
  methods: {
    isURL(str) {
      let url;
      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    toParsePhotoURLGoogleDrive() {
      this.parsePhotoURLDialogData.from = "google-drive";
      this.parsePhotoURLDialogData.text = "";
      this.parsePhotoURLDialogData.error = "";
      this.parsePhotoURLDialogShow = true;
    },
    toParsePhotoURLConfirm() {
      const url = this.parsePhotoURL(this.parsePhotoURLDialogData);
      if (url) {
        this.inputValue = url;
        this.parsePhotoURLDialogShow = false;
      } else {
        if (this.parsePhotoURLDialogData.from === "google-drive") {
          this.parsePhotoURLDialogData.error = this.$t(
            "__photo_url_from_googledrive_filelink_couldnotparse_message"
          );
        }
      }
    },
    toParsePhotoURLCancel() {
      this.parsePhotoURLDialogShow = false;
    },
    parsePhotoURL(data) {
      if (data.from === "google-drive") {
        const found = data.text.match(/\/d\/(.+?)\//);
        if (!found || found.length < 2) {
          return "";
        } else {
          const fileID = found[1];
          return `https://drive.google.com/uc?export=view&id=${fileID}`;
        }
      }
    }
  }
};
</script>
