import { firestore } from "@/plugins/firebase";
import { userAPI } from "@/plugins/service";

export const state = {
  ids: [],
  datas: null
};
export const getters = {
  ids(state) {
    return state.ids;
  },
  datas(state) {
    return state.datas;
  },
  list(state) {
    const list = [];
    for (const id of state.ids) {
      list.push({ id, data: state.datas[id] });
    }
    return list;
  },
  firestoreCollectionRef() {
    return firestore.collection("stores");
  }
};
export const mutations = {
  CLEAR(state) {
    state.ids = [];
    state.datas = null;
  },
  SET(state, { ids, datas }) {
    state.ids = ids;
    state.datas = datas;
  }
};
export const actions = {
  getUserStores({ rootGetters, commit, dispatch }) {
    commit("CLEAR");
    const userID = rootGetters["user/uid"];
    return dispatch("user/getUserIDToken", {}, { root: true })
      .then(idToken => {
        return userAPI.getUserStores(idToken, userID);
      })
      .then(async res => {
        const ids = res["store_ids"];
        const datas = res["stores"];
        return commit("SET", { ids, datas });
      })
      .catch(err => Promise.reject("fetch user stores error: " + err));
  }
};
