import { userAPI } from "@/plugins/service";

export const state = {
  userID: "",
  planID: "",
  data: null,
  histories: null
};
export const getters = {
  userID(state) {
    return state.userID;
  },
  planID(state) {
    return state.planID;
  },
  data(state) {
    return state.data;
  },
  histories(state) {
    return state.histories;
  }
};
export const mutations = {
  SET_USER_ID(state, userID) {
    state.userID = userID;
  },
  SET_PLAN_ID(state, planID) {
    state.planID = planID;
  },
  SET_PLAN_DATA(state, data) {
    state.data = data;
  },
  SET_PLAN_HISTORIES(state, histories) {
    state.histories = histories;
  }
};

export const actions = {
  fetchUserPlan({ commit, dispatch }, userID) {
    return new Promise((resolve, reject) => {
      if (userID) {
        commit("SET_USER_ID", userID);
        return dispatch("getUserPlan")
          .then(() => {
            return dispatch("getUserPlanHistories");
          })
          .then(() => {
            resolve();
          })
          .catch(err => {
            dispatch("fetchUserPlan", "");
            reject(err);
          });
      } else {
        commit("SET_PLAN_ID", "");
        commit("SET_PLAN_DATA", null);
        commit("SET_PLAN_HISTORIES", null);
        resolve();
      }
    });
  },
  getUserPlan({ commit, dispatch, rootGetters, getters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      let plan = {};
      let planID = "";
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = getters.userID;
          return userAPI.getUserPlan(idToken, userID);
        })
        .then(res => {
          plan = res;
          planID = res["plan_id"];
          commit("SET_PLAN_ID", res["plan_id"]);
        })
        .then(() => {
          return dispatch("getPlan", planID);
        })
        .then(res => {
          plan.name = res.plan.name;
          commit("SET_PLAN_DATA", plan);
          resolve();
        })
        .catch(err => reject("get user plan error: " + err));
    });
  },
  getUserPlanHistories({ commit, dispatch, rootGetters, getters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = getters.userID;
          return userAPI.getUserPlanHistories(idToken, userID);
        })
        .then(res => {
          commit("SET_PLAN_HISTORIES", res);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("get user plan histories error: " + err));
    });
  },
  getPlan({ dispatch, rootGetters }, planID) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.getPlan(idToken, planID);
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => reject("get plan error: " + err));
    });
  }
};
