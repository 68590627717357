import { call } from ".";

/**
 * get payment
 * @param {string} idToken
 * @param {string} orderID
 * @param {string} paymentID
 * @returns {Promise<Object>} order_id, payment_id and payment
 */
const getPayment = function(idToken, orderID, paymentID) {
  return call("getPayment", idToken, {
    order_id: orderID,
    payment_id: paymentID
  });
};

/**
 * get payments
 * @param {string} idToken
 * @param {string} orderID
 * @returns {Promise<Object>} order_id, payment_ids and payments
 */
const getPayments = function(idToken, orderID) {
  return call("getPayments", idToken, {
    order_id: orderID
  });
};

/**
 * get paid amount
 * @param {string} idToken
 * @param {string} orderID
 * @returns {Promise<Object>} order_id and amount
 */
const getPaidAmount = function(idToken, orderID) {
  return call("getPaidAmount", idToken, {
    order_id: orderID
  });
};

/**
 * add store payment
 * @param {string} idToken
 * @param {string} orderID
 * @param {string} paymentMethodID or method
 * @param {string} method (cash, cash-on-delivery, transfer) or payment_method_id
 * @param {string} status (unpaid, paid, refund)
 * @param {number} amount
 * @param {string} bankCode
 * @param {string} account
 * @param {string} accountLast5Digits transfer
 * @returns {Promise<Object>} order_id and payment_id
 */
const addPayment = function(
  idToken,
  orderID,
  paymentMethodID,
  method,
  status,
  amount,
  bankCode,
  account,
  accountLast5Digits
) {
  return call("addPayment", idToken, {
    order_id: orderID,
    payment_method_id: paymentMethodID,
    method: method,
    status: status,
    amount: amount,
    bank_code: bankCode,
    account: account,
    account_last_5_digits: accountLast5Digits
  });
};

/**
 * refund payment
 * @param {string} idToken
 * @param {string} orderID
 * @param {string} paymentID
 * @returns {Promise<string>} "success"
 */
const refundPayment = function(idToken, orderID, paymentID) {
  return call("refundPayment", idToken, {
    order_id: orderID,
    payment_id: paymentID
  });
};

/**
 * get transfer info
 * @param {string} idToken
 * @param {string} orderID
 * @param {string} transferInfoID
 * @returns {Promise<Object>} order_id, transfer_info_id and transfer_info
 */
const getTransferInfo = function(idToken, orderID, transferInfoID) {
  return call("getTransferInfo", idToken, {
    order_id: orderID,
    transfer_info_id: transferInfoID
  });
};

/**
 * get transfer infos
 * @param {string} idToken
 * @param {string} orderID
 * @returns {Promise<Object>} order_id, transfer_info_id and transfer_info
 */
const getTransferInfos = function(idToken, orderID) {
  return call("getTransferInfos", idToken, {
    order_id: orderID
  });
};

/**
 * reply transfer info
 * @param {string} idToken
 * @param {string} orderID
 * @param {string} transferInfoID
 * @param {boolean} confirm
 * @param {string} description
 * @returns {Promise<Object>} order_id, transfer_info_id and transfer_info
 */
const replyTransferInfo = function(
  idToken,
  orderID,
  transferInfoID,
  confirm,
  description
) {
  return call("replyTransferInfo", idToken, {
    order_id: orderID,
    transfer_info_id: transferInfoID,
    confirm: confirm,
    description: description
  });
};

export {
  getPayment,
  getPayments,
  getPaidAmount,
  addPayment,
  refundPayment,
  getTransferInfo,
  getTransferInfos,
  replyTransferInfo
};
