import { userAPI } from "@/plugins/service";

export const state = {
  storeMessageCronJobDatas: [],
  publishedItemQuantity: "",
  storeMessageInterval: "",
  storeMessageQuantity: "",
  messageCounts: {}
};
export const getters = {
  storeMessageCronJobDatas(state) {
    return state.storeMessageCronJobDatas;
  },
  publishedItemQuantity(state) {
    return state.publishedItemQuantity;
  },
  storeMessageInterval(state) {
    return state.storeMessageInterval;
  },
  storeMessageQuantity(state) {
    return state.storeMessageQuantity;
  },
  storeMessageCounts(state) {
    return state.messageCounts;
  }
};
export const mutations = {
  CLEAR_STORE_MESSAGE_CRON_JOBS(state) {
    state.storeMessageCronJobDatas = [];
  },
  SET_STORE_MESSAGE_CRON_JOBS(state, { id, data }) {
    const foundIndex = state.storeMessageCronJobDatas.findIndex(
      s => s.id == id
    );
    if (foundIndex >= 0)
      state.storeMessageCronJobDatas.splice(foundIndex, 1, { id, data });
    else state.storeMessageCronJobDatas.push({ id, data });
  },
  SET_USER_MESSAGE_LIMITS(
    state,
    { itemQuantity, messageInterval, messageQuantity }
  ) {
    state.publishedItemQuantity = itemQuantity;
    state.storeMessageInterval = messageInterval;
    state.storeMessageQuantity = messageQuantity;
  },
  SET_USER_MESSAGE_COUNT(state, { counts }) {
    state.messageCounts = counts;
  }
};
export const actions = {
  fetchStoreMessageCronJob({ commit, dispatch }, { userID, storeID }) {
    commit("CLEAR_STORE_MESSAGE_CRON_JOBS");
    return dispatch("user/getUserIDToken", {}, { root: true })
      .then(idToken => {
        return userAPI.getStoreMessageCronJobs(idToken, userID, storeID);
      })
      .then(async res => {
        for (const msgID of res["store_message_cron_job_ids"]) {
          commit("SET_STORE_MESSAGE_CRON_JOBS", {
            id: msgID,
            data: res["store_message_cron_jobs"][msgID]
          });
        }
        Promise.resolve();
      })
      .catch(err => {
        Promise.reject("fetch store message cron job error: " + err);
        commit("CLEAR_STORE_MESSAGE_CRON_JOBS");
      });
  },
  getUserLimits({ dispatch, commit }, { userID }) {
    return dispatch("user/getUserIDToken", {}, { root: true })
      .then(idToken => {
        return userAPI.getUserLimits(idToken, userID);
      })
      .then(async res => {
        const itemQuantity = res["published_item_quantity"];
        const messageInterval = res["push_store_message_interval"];
        const messageQuantity = res["push_store_message_quantity"];
        return commit("SET_USER_MESSAGE_LIMITS", {
          itemQuantity,
          messageInterval,
          messageQuantity
        });
      })
      .catch(err => Promise.reject("get user limits error: " + err));
  },
  addStoreMessageCronJob(
    { dispatch },
    {
      storeID,
      name,
      key,
      data,
      title,
      text,
      mediaType,
      mediaUrl,
      startTime,
      endTime,
      timesMode,
      times
    }
  ) {
    return dispatch("user/getUserIDToken", {}, { root: true })
      .then(idToken => {
        return userAPI.addStoreMessageCronJob(
          idToken,
          storeID,
          name,
          key,
          data,
          title,
          text,
          mediaType,
          mediaUrl,
          startTime,
          endTime,
          timesMode,
          times
        );
      })
      .then(() => {})
      .catch(err => Promise.reject("add store message cron job error: " + err));
  },
  setStoreMessageCronJob(
    { dispatch, rootGetters },
    { storeMessageCronJobID, setData }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.setStoreMessageCronJob(
            idToken,
            storeMessageCronJobID,
            setData
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("set store message cron job error: " + err));
    });
  },
  deleteStoreMessageCronJob(
    { dispatch, rootGetters },
    { storeMessageCronJobID }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.deleteStoreMessageCronJob(
            idToken,
            storeMessageCronJobID
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("delete store message cron job error: " + err));
    });
  },
  executeStoreMessageCronJob({ dispatch }, { storeMessageCronJobID }) {
    return dispatch("user/getUserIDToken", {}, { root: true })
      .then(idToken => {
        return userAPI.executeStoreMessageCronJob(
          idToken,
          storeMessageCronJobID
        );
      })
      .then(() => {
        Promise.resolve();
      })
      .catch(err =>
        Promise.reject("execute store message cron job error: " + err)
      );
  },
  getStoreMessageCronJobExecutionCount(
    { commit, dispatch },
    { userID, storeID }
  ) {
    return dispatch("user/getUserIDToken", {}, { root: true })
      .then(idToken => {
        return userAPI.getStoreMessageCronJobExecutionCount(
          idToken,
          userID,
          storeID
        );
      })
      .then(async res => {
        const counts = res["counts"];
        return commit("SET_USER_MESSAGE_COUNT", { counts });
      })
      .catch(err =>
        Promise.reject("count store message cron job error: " + err)
      );
  }
};
