import { Timestamp, firestore, functions } from "@/plugins/firebase";
import { userAPI } from "@/plugins/service";

export const state = {
  id: "",
  datas: [],
  data: null,
  onePageWebData: null,
  categories: [],
  items: [],
  messengers: [], // { id, data, enabled}
  messengersEnabled: false,
  messengersEnabledData: null,
  paymentMethodDatas: [],
  shippingChannelDatas: [],
  autoReplyOrderTime: null,
  //user limits
  onePageWeb: false,
  paymentATM: false,
  paymentCash: false,
  paymemtCashOnDelivery: false,
  paymentEcpay: false,
  paymentTappay: false,
  publishedItemQuantity: 0,
  shippingLogistics: false,
  shippingPickup: false,
  // store history record
  storeHistoryID: localStorage.getItem("storeID")
};
export const getters = {
  id(state) {
    return state.id;
  },
  datas(state) {
    return state.datas;
  },
  data(state) {
    return state.data;
  },
  storeIndustryType(state) {
    return state.data["industry_type"];
  },
  onePageWebData(state) {
    return state.onePageWebData;
  },
  categories(state) {
    return state.categories;
  },
  items(state) {
    return state.items;
  },
  messengers(state) {
    return state.messengers;
  },
  messengerTelegramBotData(state) {
    const found = state.messengers.find(s => s.id == "telegram_bot");
    if (found) {
      return found.data;
    }
    return null;
  },
  messengersEnabledData(state) {
    return state.messengersEnabledData;
  },
  messengersEnabled(state) {
    return state.messengersEnabled;
  },
  paymentMethodDatas(state) {
    return state.paymentMethodDatas;
  },
  paymentMethodStoreData(state) {
    const found = state.paymentMethodDatas.find(s => s.id == "store");
    if (found) {
      return found.data;
    }
    return null;
  },
  paymentMethodTapPayData(state) {
    const found = state.paymentMethodDatas.find(s => s.id == "tappay");
    if (found) {
      return found.data;
    }
    return null;
  },
  shippingChannelDatas(state) {
    return state.shippingChannelDatas;
  },
  shippingChannelStoreData(state) {
    const found = state.shippingChannelDatas.find(s => s.id == "store");
    if (found) {
      return found.data;
    }
    return null;
  },
  autoReplyOrderTime(state) {
    return state.autoReplyOrderTime;
  },
  firestoreDocRef(state, getters, rootState, rootGetters) {
    const storesCollectionRef = rootGetters["stores/firestoreCollectionRef"];
    if (storesCollectionRef) {
      if (state.id) {
        return storesCollectionRef.doc(state.id);
      }
    }
    return null;
  },
  firestoreItemsCollectionRef() {
    return firestore.collection("items");
  },
  //user limits
  onePageWeb(state) {
    return state.onePageWeb;
  },
  paymentATM(state) {
    return state.paymentATM;
  },
  paymentCash(state) {
    return state.paymentCash;
  },
  paymemtCashOnDelivery(state) {
    return state.paymemtCashOnDelivery;
  },
  paymentTappay(state) {
    return state.paymentTappay;
  },
  paymentEcpay(state) {
    return state.paymentEcpay;
  },
  publishedItemQuantity(state) {
    return state.publishedItemQuantity;
  },
  shippingLogistics(state) {
    return state.shippingLogistics;
  },
  shippingPickup(state) {
    return state.shippingPickup;
  },
  storeHistoryID(state) {
    return state.storeHistoryID;
  }
};
export const mutations = {
  SET_STORE_ID(state, id) {
    state.id = id;
  },
  SET_STORES(state, datas) {
    state.datas = datas;
  },
  SET_STORE_DATA(state, data) {
    state.data = data;
  },
  SET_ONE_PAGE_WEB_DATA(state, data) {
    state.onePageWebData = data;
  },
  CLEAR_STORES_IDS(state) {
    state.ids = [];
  },
  CLEAR_ONE_PAGE_WEB_DATA(state) {
    state.onePageWebData = null;
  },
  CLEAR_ITEMS(state) {
    state.items = [];
  },
  PUSH_ITEM(state, { id, data }) {
    const foundIndex = state.items.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.items.splice(foundIndex, 1, { id, data });
    else state.items.push({ id, data });
  },
  CLEAR_CATEGORIES(state) {
    state.categories = [];
  },
  PUSH_CATEGORY(state, { id, data }) {
    const foundIndex = state.categories.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.categories.splice(foundIndex, 1, { id, data });
    else state.categories.push({ id, data });
  },
  CLEAR_MESSENGERS(state) {
    state.messengers = [];
  },
  PUSH_MESSENGER(state, { id, data }) {
    const foundIndex = state.messengers.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.messengers.splice(foundIndex, 1, { id, data });
    else state.messengers.push({ id, data, enabledData: null });
  },
  SET_MESSENGER_ENABLED(state, { id, data }) {
    const foundIndex = state.messengers.findIndex(s => s.id == id);
    if (foundIndex >= 0) {
      state.messengers[foundIndex].enabledData = data;
      if (data) {
        state.messengersEnabled = true;
      } else {
        state.messengersEnabled = false;
      }
    }
  },
  SET_MESSENGER_ENABLED_DATA(state, data) {
    state.messengersEnabledData = data;
  },
  CLEAR_PAYMENT_METHOD_DATAS(state) {
    state.paymentMethodDatas = [];
  },
  PUSH_PAYMENT_METHOD_DATA(state, { id, data }) {
    const foundIndex = state.paymentMethodDatas.findIndex(s => s.id == id);
    if (foundIndex >= 0)
      state.paymentMethodDatas.splice(foundIndex, 1, { id, data });
    else state.paymentMethodDatas.push({ id, data });
  },
  CLEAR_SHIPPING_CHANNEL_DATAS(state) {
    state.shippingChannelDatas = [];
  },
  PUSH_SHIPPING_CHANNEL_DATA(state, { id, data }) {
    const foundIndex = state.shippingChannelDatas.findIndex(s => s.id == id);
    if (foundIndex >= 0)
      state.shippingChannelDatas.splice(foundIndex, 1, { id, data });
    else state.shippingChannelDatas.push({ id, data });
  },
  SET_AUTO_REPLY_ORDER_TIME(state, data) {
    state.autoReplyOrderTime = data;
  },
  SET_USER_LIMITS(
    state,
    {
      onePageWeb,
      paymentATM,
      paymentCash,
      paymemtCashOnDelivery,
      paymentEcpay,
      paymentTappay,
      publishedItemQuantity,
      shippingLogistics,
      shippingPickup
    }
  ) {
    state.onePageWeb = onePageWeb;
    state.paymentATM = paymentATM;
    state.paymentCash = paymentCash;
    state.paymemtCashOnDelivery = paymemtCashOnDelivery;
    state.paymentEcpay = paymentEcpay;
    state.paymentTappay = paymentTappay;
    state.publishedItemQuantity = publishedItemQuantity;
    state.shippingLogistics = shippingLogistics;
    state.shippingPickup = shippingPickup;
  },
  SET_STORE_HISTORY_ID(state, ID) {
    state.storeHistoryID = ID;
  }
};
export const actions = {
  fetchStore({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      if (id) {
        commit("SET_STORE_ID", id);
        return dispatch("getStore")
          .then(() => {
            return dispatch("getStores");
          })
          .then(() => {
            return dispatch("getUserLimits");
          })
          .then(() => {
            return dispatch("getStoreCategories");
          })
          .then(() => {
            return dispatch("getStoreItems");
          })
          .then(() => {
            return dispatch("getStoreMessengers");
          })
          .then(() => {
            return dispatch("checkStoreTelegramBotEnabled");
          })
          .then(() => {
            return dispatch("getStorePaymentMethodDatas");
          })
          .then(() => {
            return dispatch("getStoreShippingChannelDatas");
          })
          .then(() => {
            return dispatch("getAutoReplyOrderTime");
          })
          .then(() => {
            resolve();
          })
          .catch(err => {
            dispatch("fetchStore", "");
            reject(err);
          });
      } else {
        commit("SET_STORE_ID", "");
        commit("SET_STORE_DATA", null);
        commit("SET_ONE_PAGE_WEB_DATA", null);
        commit("CLEAR_STORES_IDS");
        commit("CLEAR_CATEGORIES");
        commit("CLEAR_ITEMS");
        commit("CLEAR_SHIPPING_CHANNEL_DATAS");
        commit("CLEAR_MESSENGERS");
        commit("CLEAR_PAYMENT_METHOD_DATAS");
        resolve();
      }
    });
  },
  getStore({ getters, rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      getters["firestoreDocRef"]
        .get()
        .then(async documentSnapshot => {
          if (!documentSnapshot.exists) {
            reject("store not found");
          }
          const data = documentSnapshot.data();
          commit("SET_STORE_DATA", data);
          resolve(data);
        })
        .catch(err => reject("get store error: " + err));
    });
  },
  getStores({ rootGetters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = rootGetters["user/uid"];
          return userAPI.getUserStores(idToken, userID);
        })
        .then(res => {
          let storeOptions = [];
          for (const ID of res["store_ids"]) {
            storeOptions.push({
              id: ID,
              name: res["stores"][ID].name
            });
          }
          commit("SET_STORES", storeOptions);
          resolve();
        })
        .catch(err => reject("get stores error: " + err));
    });
  },
  updateStore({ getters, rootGetters }, data) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      data.update_time = Timestamp.now();
      getters["firestoreDocRef"]
        .update(data)
        .then(() => resolve())
        .catch(err => reject("update store error: " + err));
    });
  },
  updateStoreInfo(
    { dispatch },
    {
      name,
      description,
      introduction,
      photoURLs,
      contactUs,
      termsAndConditions,
      returnPolicy
    }
  ) {
    return dispatch("updateStore", {
      name: name,
      description: description,
      introduction: introduction,
      photo_urls: photoURLs,
      customer_service_policy: {
        contact_us: contactUs,
        terms_and_conditions: termsAndConditions,
        return_policy: returnPolicy
      }
    });
  },
  getStoreCategories({ commit, dispatch, getters, rootGetters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.getStoreCategories(idToken, storeID);
        })
        .then(res => {
          commit("CLEAR_CATEGORIES");
          for (const categoryID of res.category_ids) {
            commit("PUSH_CATEGORY", {
              id: categoryID,
              data: res.categories[categoryID]
            });
          }
          resolve(state.items);
        })
        .catch(err => reject("get store categories error: " + err));
    });
  },
  createCategory({ dispatch, getters, rootGetters }, { name, photoURLs }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.createCategory(idToken, storeID, name, photoURLs);
        })
        .then(res => resolve(res.category_id))
        .catch(err => reject("create category error: " + err));
    });
  },
  updateCategory({ dispatch, getters, rootGetters }, { id, setData }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.setCategory(idToken, storeID, id, setData);
        })
        .then(() => resolve())
        .catch(err => reject("update category error: " + err));
    });
  },
  deleteCategory({ getters, rootGetters, dispatch }, id) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.deleteCategory(idToken, storeID, id);
        })
        .then(() => resolve())
        .catch(err => reject("delete category error: " + err));
    });
  },
  getStoreItems({ state, getters, rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      commit("CLEAR_ITEMS");
      getters["firestoreItemsCollectionRef"]
        .where("user_id", "==", rootGetters["user/uid"])
        .where("store_id", "==", state.id)
        .orderBy("name")
        .get()
        .then(querySnapshot => {
          for (var documentSnapshot of querySnapshot.docs) {
            const data = documentSnapshot.data();
            if (
              data.deleted_time === undefined ||
              data.deleted_time.nanoseconds == 0
            ) {
              commit("PUSH_ITEM", {
                id: documentSnapshot.id,
                data
              });
            }
          }
          resolve(state.items);
        })
        .catch(err => reject("get store error: " + err));
    });
  },
  createItem(
    { getters, rootGetters, dispatch },
    { name, description, introduction, photoURLs, price, quantityOfStock }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      let itemID = "";
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = rootGetters["user/uid"];
          const storeID = getters.id;
          return userAPI.createItem(
            idToken,
            userID,
            storeID,
            name,
            description
          );
        })
        .then(res => {
          itemID = res["item_id"];
          return dispatch("user/getUserIDToken", {}, { root: true });
        })
        .then(idToken => {
          return userAPI.setItem(idToken, itemID, {
            introduction: introduction,
            photo_urls: photoURLs,
            price: price,
            quantity_of_stock: quantityOfStock
          });
        })
        .then(() => resolve(itemID))
        .catch(err => reject("create item error: " + err));
    });
  },
  setItem(
    { rootGetters, dispatch },
    { id, name, description, introduction, photoURLs, price, quantityOfStock }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.setItem(idToken, id, {
            name: name,
            description: description,
            introduction: introduction,
            photo_urls: photoURLs,
            price: price,
            quantity_of_stock: quantityOfStock
          });
        })
        .then(() => resolve())
        .catch(err => reject("set item error: " + err));
    });
  },
  async setItemCategories({ dispatch }, { id, categories }) {
    const categoryIDs = [];
    for (const category of categories) {
      if (!category.id) {
        category.id = await dispatch("createCategory", {
          name: category.data.name,
          photoURLs: []
        });
      }
      categoryIDs.push(category.id);
    }
    return dispatch("setItemCategoryIDs", { id, categoryIDs });
  },
  async setItemCategoryIDs({ rootGetters, dispatch }, { id, categoryIDs }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.setItem(idToken, id, {
            category_ids: categoryIDs
          });
        })
        .then(() => resolve())
        .catch(err => reject("set item category ids error: " + err));
    });
  },
  publishItem({ rootGetters, dispatch }, id) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.publishItem(idToken, id);
        })
        .then(() => resolve())
        .catch(err => reject("publish item error: " + err));
    });
  },
  delistItem({ rootGetters, dispatch }, id) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.delistItem(idToken, id);
        })
        .then(() => resolve())
        .catch(err => reject("delist item error: " + err));
    });
  },
  deleteItem({ rootGetters, dispatch }, id) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.deleteItem(idToken, id);
        })
        .then(() => resolve())
        .catch(err => reject("delete item error: " + err));
    });
  },
  getStoreMessengers({ dispatch, getters, rootGetters, commit }, storeIDParam) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id ? getters.id : storeIDParam;
          return userAPI.getMessengerTelegramBotData(idToken, storeID);
        })
        .then(res => {
          const data = res.data;
          commit("PUSH_MESSENGER", {
            id: "telegram_bot",
            data
          });
          resolve(state.messengers);
        })
        .catch(err => reject("get messenger error: " + err));
    });
  },
  checkStoreTelegramBotEnabled({ commit, state, getters, rootGetters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      const telegramBotData = getters["messengerTelegramBotData"];

      if (telegramBotData) {
        firestore
          .collection("enabled_telegram_bots")
          .where("user_id", "==", rootGetters["user/uid"])
          .where("store_id", "==", state.id)
          .get()
          .then(querySnapshot => {
            let enabledTelegramBot = null;
            for (var documentSnapshot of querySnapshot.docs) {
              const data = documentSnapshot.data();
              if (data.token == telegramBotData.token) {
                enabledTelegramBot = data;
                break;
              }
            }

            commit("SET_MESSENGER_ENABLED", {
              id: "telegram_bot",
              data: enabledTelegramBot
            });
            commit("SET_MESSENGER_ENABLED_DATA", enabledTelegramBot);
            resolve(enabledTelegramBot);
          })
          .catch(err =>
            reject("check store telegram bot enabled error: " + err)
          );
      } else {
        resolve(null);
      }
    });
  },
  updateMessengerTelegramBot({ dispatch, getters, rootGetters }, { token }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.setMessengerTelegramBotData(idToken, storeID, token);
        })
        .then(() => resolve())
        .catch(err => reject("update messenger error: " + err));
    });
  },
  enableMessengerTelegramBot({ getters, rootGetters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      const telegramBotData = getters["messengerTelegramBotData"];
      if (telegramBotData) {
        const enableTelegramBot = functions.httpsCallable("enableTelegramBot");
        return enableTelegramBot({
          storeID: this.state.store.id,
          token: telegramBotData.token
        })
          .then(res => {
            if (res.data.ok) {
              resolve();
            } else {
              reject(`enable telegram bot error: ${res.data.description}`);
            }
          })
          .catch(err => {
            // var code = err.code;
            // var message = err.message;
            // var details = err.details;
            // console.log('functions err:', `[${code}] ${message}`, details);
            reject("enable telegram bot error: " + err);
          });
      } else {
        resolve("no telegram bot data");
      }
    });
  },
  disableMessengerTelegramBot({ getters, rootGetters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      const telegramBotData = getters["messengerTelegramBotData"];
      if (telegramBotData) {
        const disableTelegramBot = functions.httpsCallable(
          "disableTelegramBot"
        );
        return disableTelegramBot({
          storeID: this.state.store.id,
          token: telegramBotData.token
        })
          .then(res => {
            if (res.data.ok) {
              resolve();
            } else {
              reject(`disable telegram bot error: ${res.data.description}`);
            }
          })
          .catch(err => {
            // var code = err.code;
            // var message = err.message;
            // var details = err.details;
            // console.log('functions err:', `[${code}] ${message}`, details);
            reject("disable telegram bot error: " + err);
          });
      } else {
        resolve("no telegram bot data");
      }
    });
  },
  addStorePaymentMethodData(
    { dispatch, getters, rootGetters },
    {
      enabled,
      type,
      name,
      bankCode,
      account,
      method,
      appID,
      appKey,
      partnerKey,
      merchantID,
      googleMerchantID,
      appleMerchantIdentifier,
      hashKey,
      hashIV
    }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.addPaymentMethod(
            idToken,
            storeID,
            enabled,
            type,
            name,
            bankCode,
            account,
            method,
            appID,
            appKey,
            partnerKey,
            merchantID,
            googleMerchantID,
            appleMerchantIdentifier,
            hashKey,
            hashIV
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("add payment method store error: " + err));
    });
  },
  getStorePaymentMethodDatas({ dispatch, getters, rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      commit("CLEAR_PAYMENT_METHOD_DATAS");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.getPaymentMethods(idToken, storeID);
        })
        .then(res => {
          let datas = [];
          for (const paymentID of res.payment_method_ids) {
            datas.push({
              data: res.payment_methods[paymentID],
              paymentID
            });
            commit("PUSH_PAYMENT_METHOD_DATA", {
              id: "store",
              data: datas
            });
          }
          resolve(state.paymentMethodDatas);
        })
        .catch(err => reject("get payment method store error: " + err));
    });
  },
  setPaymentMethodStoreData(
    { dispatch, getters, rootGetters },
    { paymentMethodID, setData }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.setPaymentMethod(
            idToken,
            storeID,
            paymentMethodID,
            setData
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("set payment method error: " + err));
    });
  },
  deletePaymentMethodStoreData(
    { dispatch, getters, rootGetters },
    { paymentMethodID }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.deletePaymentMethod(idToken, storeID, paymentMethodID);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("delete payment method error: " + err));
    });
  },
  addStoreShippingChannel(
    { dispatch, getters, rootGetters },
    { enabled, type, name, price }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.addShippingChannel(
            idToken,
            storeID,
            enabled,
            type,
            name,
            price
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("add shipping channel error: " + err));
    });
  },
  getStoreShippingChannelDatas(
    { state, dispatch, getters, commit, rootGetters },
    storeIDparam
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      commit("CLEAR_SHIPPING_CHANNEL_DATAS");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id ? getters.id : storeIDparam;
          return userAPI.getShippingChannels(idToken, storeID);
        })
        .then(res => {
          let datas = [];
          for (const channelID of res.shipping_channel_ids) {
            datas.push({ data: res.shipping_channels[channelID], channelID });
            commit("PUSH_SHIPPING_CHANNEL_DATA", {
              id: "store",
              data: datas
            });
          }
          resolve(state.shippingChannelDatas);
        })
        .catch(err => reject("get shipping channel error: " + err));
    });
  },
  setStoreShippingChannelData(
    { dispatch, getters, rootGetters },
    { shippingChannelID, setData }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.setShippingChannel(
            idToken,
            storeID,
            shippingChannelID,
            setData
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("set shipping channel error: " + err));
    });
  },
  deleteStoreShippingChannelData(
    { dispatch, getters, rootGetters },
    { shippingChannelID }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.deleteShippingChannel(
            idToken,
            storeID,
            shippingChannelID
          );
        })
        .then(() => resolve())
        .catch(err => reject("delete shipping channel error: " + err));
    });
  },
  setAutoReplyOrderTime_week(
    { getters, rootGetters, dispatch },
    { timeZoneOffset, weekdayTimeIntervals }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.setAutoReplyOrderTime_week(
            idToken,
            storeID,
            timeZoneOffset,
            weekdayTimeIntervals
          );
        })
        .then(() => resolve())
        .catch(err => reject("set auto reply order time error: " + err));
    });
  },
  getAutoReplyOrderTime({ getters, rootGetters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.getAutoReplyOrderTime(idToken, storeID);
        })
        .then(result => {
          const data = result["auto_reply_order_time"];
          commit("SET_AUTO_REPLY_ORDER_TIME", data);
          resolve(data);
        })
        .catch(err => {
          if (err.code === 404) {
            commit("SET_AUTO_REPLY_ORDER_TIME", null);
            resolve(null);
          } else {
            reject("set auto reply order time error: " + err);
          }
        });
    });
  },
  setOnePageWebData(
    { dispatch, getters, rootGetters },
    { mediaUrls, links, tables, recommendedItems, qa }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.setOnePageWebData(
            idToken,
            storeID,
            mediaUrls,
            links,
            tables,
            recommendedItems,
            qa
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("set one web page error: " + err));
    });
  },
  getOnePageWebData({ getters, dispatch, commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const storeID = getters.id;
          return userAPI.getOnePageWebData(idToken, storeID);
        })
        .then(res => {
          commit("SET_ONE_PAGE_WEB_DATA", res["one_page_web_data"]);
          resolve();
        })
        .catch(err => {
          commit("CLEAR_ONE_PAGE_WEB_DATA");
          reject("get one web page error: " + err);
        });
    });
  },
  getUserLimits({ dispatch, commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = rootGetters["user/uid"];
          return userAPI.getUserLimits(idToken, userID);
        })
        .then(res => {
          commit("SET_USER_LIMITS", {
            onePageWeb: res["one_page_web"],
            paymentATM: res["payment_atm"],
            paymentCash: res["payment_cash"],
            paymemtCashOnDelivery: res["payment_cash_on_delivery"],
            paymentEcpay: res["payment_ecpay"],
            paymentTappay: res["payment_tappay"],
            publishedItemQuantity: res["published_item_quantity"],
            shippingLogistics: res["shipping_logistics"],
            shippingPickup: res["shipping_pickup"]
          });
          resolve();
        })
        .catch(err => reject("get user limits error: " + err));
    });
  }
};
