// import { functions } from "@/plugins/firebase";
import format from "date-fns/format";
import { userAPI } from "@/plugins/service";

export const state = {
  id: "",
  data: null,
  payments: [],
  // unsubscribePaymentsFunc: null,
  transferInfos: [],
  transferInfoIDs: [],
  // unsubscribeTransferInfosFunc: null,
  paidAmount: null
};
export const getters = {
  id(state) {
    return state.id;
  },
  data(state) {
    return state.data;
  },
  createTimeFormatted(state) {
    if (state.data) {
      return format(new Date(state.data.create_time), "yyyy/MM/dd HH:mm:ss");
    }
    return "";
  },
  consumerName(state) {
    if (state.data) {
      return (
        state.data.consumer.last_name + " " + state.data.consumer.first_name
      );
    }
    return "";
  },
  statuses(state) {
    if (state.data) {
      return state.data.statuses;
    }
    return [];
  },
  status(state) {
    if (state.data) {
      return state.data.statuses[state.data.statuses.length - 1].status;
    }
    return "";
  },
  statusTime(state) {
    if (state.data) {
      return state.data.statuses[state.data.statuses.length - 1].time;
    }
    return "";
  },
  isRequestCancellation(state) {
    if (state.data) {
      if (state.data.request_cancellation_time !== "0001-01-01T00:00:00Z") {
        return true;
      }
    }
    return false;
  },
  payments(state) {
    return state.payments;
  },
  paidAmount(state) {
    return state.paidAmount;
  },
  transferInfos(state) {
    return state.transferInfos;
  },
  transferInfoIDs(state) {
    return state.transferInfoIDs;
  }
};
export const mutations = {
  SET_ORDER_ID(state, id) {
    state.id = id;
  },
  SET_ORDER_DATA(state, data) {
    state.data = data;
  },
  CLEAR_PAYMENTS(state) {
    state.payments = [];
  },
  PUSH_PAYMENT(state, data) {
    state.payments = data;
  },
  REMOVE_PAYMENT(state, id) {
    const foundIndex = state.list.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.list.splice(foundIndex, 1);
  },
  CLEAR_TRANSFERINFOS(state) {
    state.transferInfos = [];
  },
  PUSH_PAID_AMOUNT(state, amount) {
    state.paidAmount = amount;
  },
  PUSH_TRANSFERINFO(state, data) {
    state.transferInfos = data;
  },
  PUSH_TRANSFERINFO_ID(state, ids) {
    state.transferInfoIDs = ids;
  },
  REMOVE_TRANSFERINFO(state, id) {
    const foundIndex = state.list.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.list.splice(foundIndex, 1);
  }
};
export const actions = {
  fetchOrder({ commit, dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      if (id) {
        commit("SET_ORDER_ID", id);
        return dispatch("getOrder")
          .then(() => {
            dispatch("getPaidAmount");
            dispatch("getPayments");
            resolve();
          })
          .catch(err => {
            dispatch("fetchOrder", "");
            reject(err);
          });
      } else {
        commit("SET_ORDER_ID", "");
        commit("SET_ORDER_DATA", null);
        resolve();
      }
    });
  },
  getOrder({ rootGetters, dispatch, getters, commit }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.getOrder(idToken, getters.id);
        })
        .then(res => {
          const data = res.order;
          commit("SET_ORDER_DATA", data);
          if (data.payment_method === "transfer") {
            dispatch("getTransferInfos");
          }
          resolve(data);
        })
        .catch(err => reject("get order error: " + err));
    });
  },
  getPaidAmount({ rootGetters, dispatch, getters, commit }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const orderID = getters.id;
          return userAPI.getPaidAmount(idToken, orderID);
        })
        .then(res => {
          commit("PUSH_PAID_AMOUNT", res.amount);
        })
        .catch(err => reject("get paid amount error: " + err));
    });
  },
  getPayments({ rootGetters, dispatch, getters, commit }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const orderID = getters.id;
          return userAPI.getPayments(idToken, orderID);
        })
        .then(res => {
          let data = [];
          for (const paymentID of res.payment_ids) {
            data.push({
              paymentID,
              data: res.payments[paymentID]
            });
          }
          commit("PUSH_PAYMENT", data);
          resolve();
        })
        .catch(err => reject("get payments error: " + err));
    });
  },
  // getTransferInfo(
  //   { rootGetters, dispatch, getters, commit },
  //   { transferInfoID }
  // ) {
  //   return new Promise((resolve, reject) => {
  //     if (!rootGetters["user/isSignIn"]) reject("no sign in");
  //     commit("CLEAR_TRANSFERINFOS");
  //     dispatch("user/getUserIDToken", {}, { root: true })
  //       .then(idToken => {
  //         const orderID = getters.id;
  //         return userAPI.getTransferInfo(idToken, orderID, transferInfoID);
  //       })
  //       .then(() => {
  //         resolve();
  //       })
  //       .catch(err => reject("get transfer info error: " + err));
  //   });
  // },
  getTransferInfos({ rootGetters, dispatch, getters, commit }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      commit("CLEAR_TRANSFERINFOS");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const orderID = getters.id;
          return userAPI.getTransferInfos(idToken, orderID);
        })
        .then(res => {
          let data = [];
          for (const transferInfoID of res.transfer_info_ids) {
            data.push({
              id: transferInfoID,
              data: res.transfer_infos[transferInfoID]
            });
          }
          commit("PUSH_TRANSFERINFO", data);
          resolve();
        })
        .catch(err => reject("get transfer infos error: " + err));
    });
  },
  acceptOrder({ dispatch, rootGetters, getters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const orderID = getters.id;
          return userAPI.acceptOrder(idToken, orderID);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("accept order error: " + err));
    });
  },
  cancelOrder({ dispatch, rootGetters, getters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const orderID = getters.id;
          return userAPI.cancelOrder(idToken, orderID);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("cancel order error: " + err));
    });
  },
  setOrderShipped(
    { dispatch, getters, rootGetters },
    { logisticsNumber, logisticsCompanyName, logisticsTrackingURL }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const orderID = getters.id;
          return userAPI.setOrderShipped(
            idToken,
            orderID,
            logisticsNumber,
            logisticsCompanyName,
            logisticsTrackingURL
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("set order shipped error: " + err));
    });
  },
  completeOrder({ dispatch, rootGetters, getters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const orderID = getters.id;
          return userAPI.completeOrder(idToken, orderID);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("complete order error: " + err));
    });
  },
  addPayment(
    { dispatch, rootGetters, getters },
    {
      paymentMethodID,
      method,
      status,
      amount,
      bankCode,
      account,
      accountLast5Digits
    }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const orderID = getters.id;
          return userAPI.addPayment(
            idToken,
            orderID,
            paymentMethodID,
            method,
            status,
            amount,
            bankCode,
            account,
            accountLast5Digits
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("add payment error: " + err));
    });
  },
  replyOrderCancellation(
    { getters, dispatch, rootGetters },
    { accept, description }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const orderID = getters.id;
          return userAPI.replyOrderCancellation(
            idToken,
            orderID,
            accept,
            description
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("reply order cancellation error: " + err));
    });
  },
  replyTransferInfo(
    { state, rootGetters, dispatch },
    { transferInfoID, confirm, description }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.replyTransferInfo(
            idToken,
            state.id,
            transferInfoID,
            confirm,
            description
          );
        })
        .then(() => resolve())
        .catch(err => reject("reply transfer info error: " + err));
    });
  },
  refundPayment({ state, rootGetters, dispatch }, id) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["user/isSignIn"]) reject("no sign in");
      dispatch("user/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return userAPI.refundPayment(idToken, state.id, id);
        })
        .then(res => resolve(res))
        .catch(err => reject("refund payment error: " + err));
    });
  }
};
