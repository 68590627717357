<template>
  <div class="date-time-picker">
    <v-form v-model="isValid">
      <table width="100%" style="table-layout: fixed;">
        <tr>
          <td>
            <v-menu
              v-model="datePickerMenuShow"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :outlined="outlined"
                  :label="$t('__storemessagecronjob_starttime_date')"
                  v-model="inputDate"
                  :rules="[...dateRules]"
                  append-icon="mdi-calendar"
                  readonly
                  :dense="dense"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="inputDate"
                @input="datePickerMenuShow = false"
                :min="minDate"
                :max="maxDate"
                :locale="locale"
              />
            </v-menu>
          </td>
          <td>
            <v-select
              outlined
              dense
              :label="$t('__storemessagecronjobs_times_period')"
              :items="timePeriodOptions"
              item-text="ch"
              item-value="en"
              v-model="timePeriodSelect"
              @change="
                hour = '';
                minute = '';
              "
            />
          </td>
          <td>
            <v-combobox
              outlined
              dense
              :label="$t('__storemessagecronjobs_times_hour')"
              :items="hourOptions"
              :rules="[...timeRules, hourRule]"
              v-model="hour"
            />
          </td>
          <td>
            <v-combobox
              outlined
              dense
              :label="$t('__storemessagecronjobs_times_minute')"
              :items="minuteOptions"
              :rules="[...timeRules, minuteRule]"
              v-model="minute"
            />
          </td>
        </tr>
      </table>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "DateTimePicker",
  props: {
    date: {
      type: String
    },
    time: {
      type: String
    },
    "date-rules": {
      type: Array
    },
    "time-rules": {
      type: Array
    },
    "min-date": {
      type: String
    },
    "max-date": {
      type: String
    },
    "min-time": {
      type: String
    },
    "max-time": {
      type: String
    },
    locale: {
      type: String
    },
    outlined: {
      type: Boolean
    },
    dense: {
      type: Boolean
    }
  },
  computed: {
    inputDate: {
      get() {
        return this.date;
      },
      set(val) {
        this.$emit("update:date", val);
      }
    },
    outputTime() {
      if (this.isValid) {
        return `${this.hour}:${this.minute}`;
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      isValid: true,
      hourRule: v => this.hourOptions.includes(v) || this.$t("__time_invalid"),
      minuteRule: v =>
        this.minuteOptions.includes(v) || this.$t("__time_invalid"),
      datePickerMenuShow: false,
      timePickerMenuShow: false,
      timePeriodOptions: [
        {
          ch: this.$t("__storemessagecronjobs_times_morning"),
          en: "morning"
        },
        {
          ch: this.$t("__storemessagecronjobs_times_afternoon"),
          en: "afternoon"
        }
      ],
      timePeriodSelect: "",
      minuteOptions: [
        "00",
        "05",
        "10",
        "15",
        "20",
        "25",
        "30",
        "35",
        "40",
        "45",
        "50",
        "55"
      ],
      hourOptions: [],
      hour: "",
      minute: ""
    };
  },
  watch: {
    time: {
      immediate: true,
      handler(val) {
        if (val) {
          this.hour = this.time.split(":")[0];
          this.minute = this.time.split(":")[1];
        }
      }
    },
    outputTime: {
      handler(val) {
        if (val) {
          if (this.hour && this.minute) {
            this.$emit("update:time", val);
          }
        }
      }
    },
    hour: {
      immediate: true,
      handler(val) {
        if (val && Number(val) <= 12) {
          this.timePeriodSelect = "morning";
        } else if (val && Number(val) > 12) {
          this.timePeriodSelect = "afternoon";
        }
      }
    },
    timePeriodSelect: {
      immediate: true,
      handler(val) {
        if (val === "morning") {
          this.hourOptions = [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12"
          ];
        } else {
          this.hourOptions = [
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23"
          ];
        }
      }
    },
    isValid: {
      immediate: true,
      handler(val) {
        this.$emit("time-invalid", val);
      }
    }
  }
};
</script>
