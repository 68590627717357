export const state = {
  list: [],
  unsubscribeFunc: null
}
export const getters = {
  list(state) {
    return state.list
  },
  firestoreCollectionRef(state, getters, rootState, rootGetters) {
    const userDocRef = rootGetters['user/firestoreDocRef']
    if (userDocRef) {
      return userDocRef.collection('messages')
    } else {
      return null
    }
  }
}
export const mutations = {
  CLEAR(state) {
    state.list = []
  },
  PUSH(state, { id, data }) {
    const foundIndex = state.list.findIndex(s => s.id == id)
    if (foundIndex >= 0) state.list.splice(foundIndex, 1, { id, data })
    else state.list.push({ id, data })
  },
  PUSH_FRONT(state, { id, data }) {
    const foundIndex = state.list.findIndex(s => s.id == id)
    if (foundIndex >= 0) state.list.splice(foundIndex, 1, { id, data })
    else state.list.unshift({ id, data })
  },
  REMOVE(state, id) {
    const foundIndex = state.list.findIndex(s => s.id == id)
    if (foundIndex >= 0) state.list.splice(foundIndex, 1)
  },
  SET_UNSUBSCRIBE_FUNC(state, func) {
    state.unsubscribeFunc = func
  },
  UNSUBSCRIBE(state) {
    if (state.unsubscribeFunc != null) {
      state.unsubscribeFunc()
      state.unsubscribeFunc = null
    }
  }
}
export const actions = {
  setOnSnapshot({ commit, getters, rootGetters }, { onAdded, onModified, onRemove, onError }) {
    commit('UNSUBSCRIBE')
    if (!rootGetters['user/isSignIn']) return false
    let firstLoad = true
    var unsubscribe = getters['firestoreCollectionRef']
    .orderBy('time', 'desc')
    .limit(100)
    .onSnapshot(querySnapshot => {
      if (firstLoad) {
        commit('CLEAR')
        for (var documentSnapshot of querySnapshot.docs) {
          commit('PUSH', {
            id: documentSnapshot.id,
            data: documentSnapshot.data()
          })
        }
        firstLoad = false
      } else {
        querySnapshot.docChanges().forEach(change => {
          const doc = {
            id: change.doc.id,
            data: change.doc.data()
          }
          if (change.type === 'added') {
            commit('PUSH_FRONT', doc)
            if (onAdded) onAdded(doc)
          } else if (change.type === 'modified') {
            commit('PUSH_FRONT', doc)
            if (onModified) onModified(doc)
          } else if (change.type === 'removed') {
            commit('REMOVE', doc.id)
            if (onRemove) onRemove(doc)
          }
        })
      }
    }, err => {
      if (onError) onError(err)
    })
    commit('SET_UNSUBSCRIBE_FUNC', unsubscribe)
    return true
  },
  unsubscribeOnSnapshotUserMessages({ commit }) {
    commit('UNSUBSㄝCRIBE')
  },
  updateMessageData({ getters, rootGetters }, { id, data }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters['user/isSignIn']) reject('no sign in')
      getters['firestoreCollectionRef'].doc(id).update(data)
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
    })
  },
  updateMessageIsRead({ dispatch }, { id, isRead }) {
    return dispatch('updateMessageData', {
      id, 
      data: {
        is_read: isRead
      }
    })
  },
  updateMessageIsListed({ dispatch }, { id, isListed }) {
    return dispatch('updateMessageData', {
      id, 
      data: {
        is_listed: isListed
      }
    })
  }
}
