import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import Notifications from 'vue-notification'
import VueHtmlToPaper from 'vue-html-to-paper'
import currencyFilter from './filters/currency.js'

import LoadingOverlay from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.component('loading-overlay', LoadingOverlay)
import PhoneNumberInput from '@/components/PhoneNumberInput'
Vue.component('phone-number-input', PhoneNumberInput)
import PhotoURLInput from '@/components/PhotoURLInput'
Vue.component('photo-url-input', PhotoURLInput)
import DateTimePicker from '@/components/DateTimePicker'
Vue.component('date-time-picker', DateTimePicker)
import DatePicker from '@/components/DatePicker'
Vue.component('date-picker', DatePicker)
import MonthPicker from '@/components/MonthPicker'
Vue.component('month-picker', MonthPicker)
import Alert from '@/components/Alert'
Vue.component('alert', Alert)

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900',
    'https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css',
    'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css'
  ]
}

Vue.use(Notifications)
Vue.use(VueHtmlToPaper, options)

Vue.filter('currency', currencyFilter)

Vue.config.productionTip = false

let app
store.dispatch('user/onAuthStateChanged', () => {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: (h) => h(App)
    }).$mount('#app')
  }
})
