<template>
  <v-app>
    <AppBar />

    <v-main>
      <router-view />
    </v-main>

    <Footer />

    <loading-overlay :active.sync="isLoading" loader="dots" />

    <notifications
      group="messages"
      :duration="5000"
      :width="500"
      animation-name="v-fade-left"
      position="bottom right"
    >
      <template slot="body" slot-scope="props">
        <div class="messages">
          <v-icon class="messages-icon">mdi-message-text</v-icon>
          <div class="messages-content">
            <div class="messages-title">{{ props.item.title }}</div>
            <div class="messages-text" v-html="props.item.text"></div>
          </div>
          <v-icon class="messages-close" @click="props.close">mdi-close</v-icon>
        </div>
      </template>
    </notifications>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    AppBar,
    Footer,
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
    }),
  },
  watch: {
    "$vuetify.breakpoint.mobile": {
      immediate: true,
      handler(val) {
        this.$store.dispatch("setIsMobile", val);
      },
    },
  },
  created() {
    document.title = this.$t("__title");
  },
};
</script>

<style lang="scss">
.messages {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  text-align: left;
  font-size: 13px;
  margin: 5px;
  margin-top: 0;
  align-items: center;
  justify-content: center;

  &,
  & > div {
    box-sizing: border-box;
  }

  background: #e8f9f0;
  border: 2px solid #d0f2e1;

  .messages-icon {
    flex: 0 1 48px;
    color: #15c371;
    padding-left: 20px;
    padding-right: 10px;
    font-size: 48px;
  }

  .messages-close {
    flex: 0 1 auto;
    padding-right: 20px;
    opacity: 0.2;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .messages-content {
    padding: 10px;
    flex: 0 1 auto;
    font-size: 16px;
    word-break: normal;

    .messages-title {
      letter-spacing: 1px;
      font-weight: 600;
    }
  }
}
</style>