<template>
  <div class="date-picker">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-left="50"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="inputDate"
          :label="dateLabel"
          append-icon="mdi-calendar"
          readonly
          :outlined="outlined"
          :dense="dense"
          hide-details
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="inputDate"
        scrollable
        :locale="locale"
        :min="minDate"
        :max="maxDate"
        @input="menu = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "DatePicker",
  props: {
    date: {
      type: String
    },
    "date-label": {
      type: String
    },
    "date-rules": {
      type: Array
    },
    "min-date": {
      type: String
    },
    "max-date": {
      type: String
    },
    locale: {
      type: String
    },
    outlined: {
      type: Boolean
    },
    dense: {
      type: Boolean
    }
  },
  computed: {
    inputDate: {
      get() {
        return this.date;
      },
      set(val) {
        this.$emit("update:date", val);
      }
    }
  },
  data() {
    return {
      menu: false
    };
  }
};
</script>
