import { call } from ".";

/**
 * get trading volume
 * @param {string} idToken
 * @param {string} userID
 * @param {array} storeIDs
 * @param {time} startTime
 * @param {time} endTime
 * @param {string} intervalUnit
 * @param {int} intervalStep
 * @returns {Promise<Object>} user_id,store_ids,trading_volume[{}]
 */
const getTradingVolume = function(
  idToken,
  userID,
  storeIDs,
  startTime,
  endTime,
  intervalUnit,
  intervalStep
) {
  return call("getTradingVolume", idToken, {
    user_id: userID,
    store_ids: storeIDs,
    start_time: startTime,
    end_time: endTime,
    interval_unit: intervalUnit,
    interval_step: intervalStep
  });
};

/**
 * get sign in histories
 * @param {string} idToken
 * @param {string} userID
 * @param {time} startTime
 * @param {time} endTime
 * @returns {Promise<Object>} histories:[{}] time,ip,code,message
 */
const getSignInHistories = function(idToken, userID, startTime, endTime) {
  return call("getSignInHistories", idToken, {
    user_id: userID,
    start_time: startTime,
    end_time: endTime
  });
};

/**
 * get number of views
 * @param {string} idToken
 * @param {string} userID
 * @param {string} storeID
 * @param {string} categoryID
 * @param {string} itemID
 * @param {time} startTime
 * @param {time} endTime
 * @param {string} intervalUnit
 * @param {int} intervalStep
 * @returns {Promise<Object>} user_id,store_id,category_id,item_id,values[{}]
 */
const getNumberOfViews = function(
  idToken,
  userID,
  storeID,
  categoryID,
  itemID,
  startTime,
  endTime,
  intervalUnit,
  intervalStep
) {
  return call("getNumberOfViews", idToken, {
    user_id: userID,
    store_id: storeID,
    category_id: categoryID,
    item_id: itemID,
    start_time: startTime,
    end_time: endTime,
    interval_unit: intervalUnit,
    interval_step: intervalStep
  });
};

/**
 * get number of new consumer
 * @param {string} idToken
 * @param {string} userID
 * @param {string} storeID
 * @param {time} startTime
 * @param {time} endTime
 * @param {string} intervalUnit
 * @param {int} intervalStep
 * @returns {Promise<Object>} user_id,store_id,value
 */
const getNumberOfNewConsumer = function(
  idToken,
  userID,
  storeID,
  startTime,
  endTime,
  intervalUnit,
  intervalStep
) {
  return call("getNumberOfNewConsumer", idToken, {
    user_id: userID,
    store_id: storeID,
    start_time: startTime,
    end_time: endTime,
    interval_unit: intervalUnit,
    interval_step: intervalStep
  });
};

export {
  getTradingVolume,
  getSignInHistories,
  getNumberOfViews,
  getNumberOfNewConsumer
};
