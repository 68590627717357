import { call } from '.'

/**
 * add live selling
 * @param {string} idToken
 * @param {string} storeID
 * @param {Date} liveStartTime
 * @param {Date} liveEndTime
 * @param {string} liveURL
 * @param {string} liveNotificationMessage
 * @returns {Promise<Object>} live_selling_id
 */
const addLiveSelling = function(idToken, storeID, liveStartTime, liveEndTime, liveURL, liveNotificationMessage) {
    return call('addLiveSelling', idToken, {
        'store_id': storeID,
        'live_start_time': liveStartTime,
        'live_end_time': liveEndTime,
        'live_url': liveURL,
        'live_notification_message': liveNotificationMessage
    })
}

/**
 * set live selling
 * @param {string} idToken
 * @param {string} liveSellingID
 * @param {Object} setData (live_start_time: Date, live_end_time: Date, live_url: string, live_notification_message: string)
 * @returns {Promise<string>} “success”
 */
const setLiveSelling = function(idToken, liveSellingID, setData) {
    return call('setLiveSelling', idToken, {
        'live_selling_id': liveSellingID,
        'set_data': setData
    })
}

/**
 * delete live selling
 * @param {string} idToken
 * @param {string} liveSellingID
 * @returns {Promise<string>} “success”
 */
const deleteLiveSelling = function(idToken, liveSellingID) {
    return call('deleteLiveSelling', idToken, {
        'live_selling_id': liveSellingID
    })
}

/**
 * get live selling
 * @param {string} idToken
 * @param {string} liveSellingID
 * @returns {Promise<Object>} live_selling_id and live_selling
 */
const getLiveSelling = function(idToken, liveSellingID) {
    return call('getLiveSelling', idToken, {
        'live_selling_id': liveSellingID
    })
}

/**
 * get live sellings
 * @param {string} idToken
 * @param {string} userID or storeID
 * @param {string} storeID or userID
 * @returns {Promise<Object>} user_id, store_id, live_selling_ids and live_sellings
 */
const getLiveSellings = function(idToken, userID, storeID) {
    return call('getLiveSellings', idToken, {
        'user_id': userID,
        'store_id': storeID
    })
}

/**
 * add live selling item cron job
 * @param {string} idToken
 * @param {string} liveSellingID
 * @param {string} itemID
 * @param {string} action (publish, delist)
 * @param {Date} time
 * @returns {Promise<Object>} item_cron_job_id
 */
const addLiveSellingItemCronJob = function(idToken, liveSellingID, itemID, action, time) {
    return call('addLiveSellingItemCronJob', idToken, {
        'live_selling_id': liveSellingID,
        'item_id': itemID,
        'action': action,
        'time': time
    })
}

/**
 * set live selling item cron job
 * @param {string} idToken
 * @param {string} liveSellingID
 * @param {string} itemCronJobID
 * @param {string} itemID
 * @param {string} action (publish, delist)
 * @param {Date} time
 * @returns {Promise<string>} "success"
 */
const setLiveSellingItemCronJob = function(idToken, liveSellingID, itemCronJobID, itemID, action, time) {
    return call('setLiveSellingItemCronJob', idToken, {
        'live_selling_id': liveSellingID,
        'item_cron_job_id': itemCronJobID,
        'item_id': itemID,
        'action': action,
        'time': time
    })
}

/**
 * delete live selling item cron job
 * @param {string} idToken
 * @param {string} liveSellingID
 * @param {string} itemCronJobID
 * @returns {Promise<string>} "success"
 */
const deleteLiveSellingItemCronJob = function(idToken, liveSellingID, itemCronJobID) {
    return call('deleteLiveSellingItemCronJob', idToken, {
        'live_selling_id': liveSellingID,
        'item_cron_job_id': itemCronJobID
    })
}

export {
    addLiveSelling,
    setLiveSelling,
    deleteLiveSelling,
    getLiveSelling,
    getLiveSellings,
    addLiveSellingItemCronJob,
    setLiveSellingItemCronJob,
    deleteLiveSellingItemCronJob
}

/**
 * add store message cron job
 * @param {string} idToken
 * @param {string} storeID required
 * @param {string} name
 * @param {string} key (text, url)
 * @param {Object} data
 * @param {string} title
 * @param {string} text required
 * @param {string} mediaType required
 * @param {string} mediaUrl 
 * @param {string} startTime RFC3339 time format
 * @param {string} endTime RFC3339 time format
 * @param {string} timesMode required
 * @param {Array<string>} times required
 * @returns {Promise<Object>} store_message_cron_job_id
 */
const addStoreMessageCronJob = function(idToken, storeID, name, key, data, title, text, mediaType, mediaUrl, startTime, endTime, timesMode, times) {
    const apiData = {
        'store_id': storeID,
        'name': name,
        'text': text,
        'media_type' : mediaType,
        'times_mode': timesMode,
        'times': times,
    }
    if (key) apiData['key'] = key
    if (data) apiData['data'] = data
    if (title) apiData['title'] = title
    if (mediaUrl) apiData['media_url'] = mediaUrl
    if (startTime) apiData['start_time'] = startTime
    if (endTime) apiData['end_time'] = endTime
    return call('addStoreMessageCronJob', idToken, apiData)
}

/**
 * set store message cron job
 * @param {string} idToken
 * @param {string} storeMessageCronJobID
 * @param {Object} setData ("name": string, "key": string, "data": Object, "title": string, "text": string, "media_type": string, "media_url": string, "start_time": string (RFC3339 time format), "end_time": string (RFC3339 time format), "times_mode": string, "times":Array<string>)
 * @returns {Promise<string>} “success”
 */
const setStoreMessageCronJob = function(idToken, storeMessageCronJobID, setData) {
    return call('setStoreMessageCronJob', idToken, {
        'store_message_cron_job_id': storeMessageCronJobID,
        'set_data': setData
    })
}

/**
 * delete store message cron job
 * @param {string} idToken
 * @param {string} storeMessageCronJobID
 * @returns {Promise<string>} “success”
 */
const deleteStoreMessageCronJob = function(idToken, storeMessageCronJobID) {
    return call('deleteStoreMessageCronJob', idToken, {
        'store_message_cron_job_id': storeMessageCronJobID
    })
}

/**
 * get store message cron job
 * @param {string} idToken
 * @param {string} storeMessageCronJobID
 * @returns {Promise<Object>} store_message_cron_job_id and store_message_cron_job
 */
const getStoreMessageCronJob = function(idToken, storeMessageCronJobID) {
    return call('getStoreMessageCronJob', idToken, {
        'store_message_cron_job_id': storeMessageCronJobID
    })
}

/**
 * get store message cron jobs
 * @param {string} idToken
 * @param {string} userID or storeID
 * @param {string} storeID or userID
 * @returns {Promise<Object>} user_id, store_id, store_message_cron_job_ids and store_message_cron_jobs
 */
const getStoreMessageCronJobs = function(idToken, userID, storeID) {
    return call('getStoreMessageCronJobs', idToken, {
        'user_id': userID,
        'store_id': storeID
    })
}

/**
 * execute store message cron job
 * @param {string} idToken
 * @param {string} storeMessageCronJobID
 * @returns {Promise<string>} “success”
 */
const executeStoreMessageCronJob = function(idToken, storeMessageCronJobID) {
    return call('executeStoreMessageCronJob', idToken, {
        'store_message_cron_job_id': storeMessageCronJobID
    })
}

/**
 * get store message cron job execution histories
 * @param {string} idToken
 * @param {string} userID or storeID
 * @param {string} storeID or userID
 * @param {string} startTime required, RFC3339 time format
 * @param {string} endTime required, RFC3339 time format
 * @returns {Promise<Object>} user_id, store_id, ids and histories
 */
const getStoreMessageCronJobExecutionHistories = function(idToken, userID, storeID, startTime, endTime) {
    return call('getStoreMessageCronJobExecutionHistories', idToken, {
        'user_id': userID,
        'store_id': storeID,
        'start_time': startTime,
        'end_time': endTime
    })
}

/**
 * get store message cron job execution count
 * @param {string} idToken
 * @param {string} userID or storeID
 * @param {string} storeID or userID
 * @returns {Promise<Object>} user_id, store_id and counts
 */
const getStoreMessageCronJobExecutionCount = function(idToken, userID, storeID) {
    return call('getStoreMessageCronJobExecutionCount', idToken, {
        'user_id': userID,
        'store_id': storeID
    })
}

export {
    addStoreMessageCronJob,
    setStoreMessageCronJob,
    deleteStoreMessageCronJob,
    getStoreMessageCronJob,
    getStoreMessageCronJobs,
    executeStoreMessageCronJob,
    getStoreMessageCronJobExecutionHistories,
    getStoreMessageCronJobExecutionCount
}
